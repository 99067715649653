import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get shouldLoad() {
    return false && window.railsEnvironment != 'test';
  }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) return;

    window.googletag.cmd.push(function() {
      window.googletag.pubads().clear();
    });

    var vertical = JSON.parse(this.data.get("vertical"));
    var tags     = JSON.parse(this.data.get("tags"));

    if (window.googletag.pages.vertical) {
      window.googletag.cmd.push(function() {
        window.googletag.pubads()
             .setTargeting('vertical', vertical)
             .setTargeting('tags-vertical', tags)
             .setTargeting('categoria', 'my_category')
             .setTargeting('location', 'my_location');

        window.googletag.pubads().refresh([
          window.googletag.slots['vertical_slot1'],
          window.googletag.slots['vertical_slot2'],
          window.googletag.slots['vertical_slot3']
        ]);
      });
    } else {
      window.googletag.cmd.push(function() {
        window.googletag.slots['vertical_slot1'] = window.googletag.defineSlot('/21858406487/pag-amarelas/vertical/banner-top', [[320,50]], 'div-gpt-vertical-banner-top')
                 .setTargeting('position', ['banner-top'])
                 .defineSizeMapping(window.googletag.sizes.mapBanner)
                 .addService(window.googletag.pubads());
        window.googletag.slots['vertical_slot2'] = window.googletag.defineSlot('/21858406487/pag-amarelas/vertical/slider', [[1,1],[300,250]], 'div-gpt-vertical-slider')
                 .setTargeting('position', ['slider'])
                 .addService(window.googletag.pubads());
        window.googletag.slots['vertical_slot3'] = window.googletag.defineSlot('/21858406487/pag-amarelas/vertical/banner-bottom', [[320,150]], 'div-gpt-vertical-banner-bottom')
                 .setTargeting('position', ['banner-bottom'])
                 .defineSizeMapping(window.googletag.sizes.mapBottom)
                 .addService(window.googletag.pubads());

        window.googletag.pubads()
           .setTargeting('vertical', vertical)
           .setTargeting('tags-vertical', tags)
           .setTargeting('categoria', 'my_category')
           .setTargeting('location', 'my_location');

        window.googletag.enableServices();

        window.googletag.display("div-gpt-vertical-banner-top");
        window.googletag.display("div-gpt-vertical-slider");
        window.googletag.display("div-gpt-vertical-banner-bottom");

        window.googletag.pages.vertical = true;
      });
    }
  }
}
