import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "daysList", "openNow", "currentSchedule" ]
  static classes = [ "open" ]

  connect() {
    this.today = new Date()
    this.days = []
    this._setupSchedule()
    
    this.day = this.days.find((element) => element.schedule.weekday === this.today.getDay())    
    this.onVisibilityChange = this._onVisibilityChange.bind(this)
    this.day.element.classList.add("is-today")

    this._showDaySchedule()
    this._setupObserver()
    this._showOpenNow()
    document.addEventListener("visibilitychange", this.onVisibilityChange)
  }

  disconnect() {
    document.removeEventListener("visibilitychange", this.onVisibilityChange)
  }

  _setupObserver() {
    const options = { root: null, rootMargin: "0px", threshold: 0.5 }
    this.observer = new IntersectionObserver(this.onIntersection.bind(this), options)
    this.observer.observe(this.element)
  }

  onIntersection(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) this._handleChange()
    })
  }

  _onVisibilityChange() {
    if (document.visibilityState === "visible") this._handleChange()
  }

  _handleChange() {
    if (!this._isSameMinute()) {
      this.today = new Date()
      this._showOpenNow()
    }
  }

  // basically this will check if we are at the same minute
  _isSameMinute() {
    const now = new Date()
    const diff = Math.abs(now - this.today)
    const minutes = Math.floor((diff / 1000) / 60)

    return minutes === 0
  }

  _showOpenNow() {
    if (!this.hasOpenNow) return

    this.openNowTarget.classList.remove("hide")

    if (this.schedule.closed) {
      this.openNowTarget.classList.remove(this.openClass)
    } else {
      this.openNowTarget.classList.toggle(this.openClass, this._isOpenNow())
    }
  }

  _isOpenNow() {
    if (this.schedule.open_all_day) return true

    let isOpen = false
    this.ranges.forEach((range) => {
      if (range[0] <= this.today && range[1] >= this.today) isOpen = true
    })

    return isOpen
  }

  _showDaySchedule() {
    if (this.schedule.closed && this.hasOpenNow) return
    this.currentScheduleTarget.innerHTML = this.day.element.querySelector('.timetable-schedule').innerText
  }

  // basically we will do some conversions here, so that we avoid doing it every time that we need
  // to check something
  _setupSchedule() {
    this.daysListTargets.forEach((element) => {
      // OK, we will parse the schedule and we will also parse the dates and move them to today
      // reference date
      const schedule = JSON.parse(element.dataset.schedule)

      // convert dates from string to `Date` objects
      schedule.start_at = this._moveToDay(schedule.start_at)
      schedule.pause_at = this._moveToDay(schedule.pause_at)
      schedule.restart_at = this._moveToDay(schedule.restart_at)
      schedule.end_at = this._moveToDay(schedule.end_at)

      // setup the ranges for the schedule
      const ranges = []
      if (schedule.pause_at) {
        ranges.push([schedule.start_at, schedule.pause_at])
        ranges.push([schedule.restart_at, schedule.end_at])
      } else {
        ranges.push([schedule.start_at, schedule.end_at])
      }
      schedule.ranges = this._normalizeRanges(ranges)

      // remove properties that will not be used
      delete schedule.start_at
      delete schedule.pause_at
      delete schedule.restart_at
      delete schedule.end_at

      this.days.push({ element: element, schedule: schedule })
    })
  }

  // basically there are scenarios where the time will span to the next day, in that scenarios we
  // need to move the date to the next day to that the check of ranges can be done the right way
  _normalizeRanges(ranges) {
    ranges.forEach((range) => {
      if (range[0] && range[1]) {
        let endRange = range[1]
        if (range[0] > endRange) endRange.setDate(endRange.getDate() + 1)
      }
    })

    return ranges
  }

  _moveToDay(dateStr, reference = this.today) {
    if (!dateStr) return null

    const date = new Date(dateStr)
    date.setDate(reference.getDate())
    date.setMonth(reference.getMonth())
    date.setFullYear(reference.getFullYear())
    return date
  }

  get schedule() {
    return this.day.schedule
  }

  get ranges() {
    return this.schedule.ranges
  }

  get hasOpenNow() {
    return this.hasOpenNowTarget
  }
}
