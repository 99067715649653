import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "label", "input", "previewer", "button" ]

  initialize() {
    this.previewClass = 'mock-preview__image';
    this.selectedClass = 'file-input__label--selected';
    this.originalClass = 'mock-preview__image--original';
    this.invalidFormatMessage = 'O tipo de ficheiro seleccionado não é permitido.';
    this.limitMessage = 'Excedeu o limite máximo de ficheiros que pode adicionar.';
    this.labelText = this.hasLabelTarget ? this.labelTarget.innerText : null
    this.existingImages = this.hasPreviewerTarget ? this.previewerTarget.querySelectorAll(`.${this.previewClass}`) : []
  }

  connect() {
    if (this.hasButtonTarget) this.buttonTarget.hidden = true
  }

  onChange(event) {
    const files = event.target.files
    if (this._validateFiles(files)) {
      this._showPreviews(files)
      this._setInputLabel(Array.from(files).map((file) => file.name).join(', '))
      if (this.hasButtonTarget) this.buttonTarget.hidden = false
    } else {
      this.inputTarget.value = null
      this._showOriginalPreviews()
      this._clearInputLabel()
    }
  }

  onRemove(event) {
    event.currentTarget.hidden = true
    this.inputTarget.value = null
    this._clearInputLabel()
    this._showOriginalPreviews()
  }

  _showPreviews(files) {
    if (this.hasPreviewerTarget) {
      this.previewerTarget.innerHTML = ''
      Array.from(files).forEach((file) => this._showPreview(file))
    }
  }

  _showOriginalPreviews() {
    if (this.hasPreviewerTarget) {
      this.previewerTarget.innerHTML = Array.from(this.existingImages).map((img) => img.outerHTML).join('')
    }
  }

  _setInputLabel(label) {
    if (this.hasLabelTarget) {
      this.labelTarget.innerText = label
      this.labelTarget.classList.add(this.selectedClass)
    }
  }

  _clearInputLabel() {
    if (this.hasLabelTarget) {
      this.labelTarget.innerText = this.labelText
      this.labelTarget.classList.remove(this.selectedClass)
    }
  }

  _validateFiles(files) {
    // we start by validating the quantity of files that the user have chose, if we have a null
    // value we don't have limit validation
    const fileLimit = this.data.get('limit')
    if (fileLimit > 0) {
      if (files.length > fileLimit) {
        alert(this.limitMessage)
        return false
      }
    }

    // if we passed the limit validation, we will now check if the input is limiting the
    // accepted type of files, if not it's all valid
    if (this.inputTarget.accept === '') return true

    // now we will valid the format of the files supplied by the user, if we files that have an
    // invalid format we will show an alert
    const formatValid = Array.from(files).reduce((valid, current) => valid && this._isValidFile(current), true)
    if (!formatValid) alert(this.invalidFormatMessage)
    return formatValid
  }

  _isValidFile(file) {
    if (this.inputTarget.accept === '') return true

    // still missing any wildcard accepted format, but for now we will skip this
    const accepted = this.inputTarget.accept.split(',')
    const fileInfo = [`.${file.name.split('.').pop()}`, file.type]

    return accepted.some(m => fileInfo.indexOf(m) >= 0)
  }

  onFileInputClick() {
    this.inputTarget.click();
  }

  _showPreview(file) {
    if (file.type.includes('image/')) {
      // create an  `<img>` element that we will add to the previewer
      const image = document.createElement('img')
      image.classList.add(this.previewClass)
      image.file = file
      this.previewerTarget.appendChild(image)

      // now we need a `FileReader` that will effectively load the image in order
      // to display the preview image
      const reader = new FileReader()
      reader.onload = ((aImg) => { return function(e) { aImg.src = e.target.result } })(image)
      reader.readAsDataURL(file)
    }
  }
}
