import { Controller } from "@hotwired/stimulus"
import Tobii from '@midzer/tobii'

export default class extends Controller {
  static targets = [ "source", "input" ]

  connect() {
    this.tobii = new Tobii()
    this._showBox()
  }

  onChange() {
    this._showBox()
  }

  showImages(event) {
    event.preventDefault()
    this.tobii.selectGroup(event.currentTarget.dataset.group)
    this.tobii.open()
  }

  _showBox() {
    this.sourceTargets.forEach((source) => source.classList.add('hidden'))
    
    const checked = this.inputTargets.find((input) => input.checked)
    if (checked) {
      const box = this._getBox(checked.dataset.source)
      if (box) box.classList.remove('hidden')
    }
  }

  _getBox(source) {
    return this.sourceTargets.find((element) => element.dataset.source === source)
  }
}
