import { Controller } from '@hotwired/stimulus';

export default class ModalConfirmationController extends Controller {
  static targets = ['modal', 'confirmButton'];

  connect() {
  }

  open(event) {
    this.modalTarget.classList.remove('d-none');
    this.modalTarget.classList.add('show');
  }

  close() {
    this.modalTarget.classList.remove('show');
    this.modalTarget.classList.add('d-none');
  }

  cancel() {
    this.close();
  }
}
