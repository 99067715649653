import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { path: String }

  connect() {
    const mainCategory = document.querySelector('[data-selector="maincategory"]');
    mainCategory && mainCategory.addEventListener('change', () => {
      /*
        We need to convert the selected options from HTMLOptionsCollection to an Array
        We use the spread operator to convert to array then use filter to filter by selected options only
        We then use map to get the values of the selected options
        In the end we should have an array of selected categories ID
      */
      const selectedCategories = Array.from(mainCategory.options).filter(option => option.selected).map(option => option.value)
      const subSelectedCategories = Array.from(this.element.options).filter(option => option.selected).map(option => option.value)

      // If no category is selected we send an empty value
      let searchString = ["search[category_ids][]="];
      // concat the empty category array with any selected options
      searchString = searchString.concat(selectedCategories.map(id => `search[category_ids][]=${id}`))
      // create the query params and encode
      const queryParams = encodeURI(searchString.join('&'));

      this.getResults(`${this.pathValue}?${queryParams}`, subSelectedCategories);
    })
  }

  async getResults(path, subSelectedCategories) {
    let response = await get(path, { contentType: 'application/json' });

    if (response.ok) {
      response.json.then((result) => {
        const values = Object.keys(result).map(key => ({
          label: key,
          options: result[key].map(option => ({ value: option.id, text: option.name }))
        }))

        if (values.length > 0) {
          // we update the subcategories selection
          // and also set previous selections if they exist
          // we also enable the select in case we had previously disabled it
          this.element.slim.setData(values)
          this.element.slim.set(subSelectedCategories)
          this.element.slim.enable()
        } else {
          // if we don't have any values (no subcategories) we need to clear any selections
          // we also disable the select
          this.element.slim.setData([])
          this.element.slim.disable()
          // even if we have no subcategories we need to trigger this event
          // the change event of the subcategories select will update the tags
          this.element.dispatchEvent(new Event('change'))
        }
      })
    }
  }
}
