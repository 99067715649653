import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["next", "prev", "items"]

    static get shouldLoad() {
        return window.railsEnvironment != 'test';
    }

    connect() {
        this.index = 0;
        this.offset = 0;
        this.carouselInner = this.element.querySelector('.carousel-inner');
        this.maxElements = this.carouselInner.children.length;
        this.carouselClone = this.carouselInner.cloneNode(true);
        this.isAnimating = false;

        this.nextTarget.addEventListener("click",  () => {
            this.animate('next');
        })

        this.prevTarget.addEventListener("click",  () => {
            this.animate('prev');
        })

        /// If small screen, auto rotate the slider
        if(window.innerWidth <= 640) {
            setInterval(() => {
                this.animate('next');
            }, 5000);
        }
    }

    animate(direction) {
        if(this.isAnimating) return;
        this.isAnimating = true;

        this.updateIndexAndOffestAndCloneChild(direction);
        this.scheduleAnimationAndCleanup();
    }

    updateIndexAndOffestAndCloneChild(direction) {
        if (direction === 'next') {
            const clonedChild = this.carouselClone.childNodes[this.index].cloneNode(true);
            this.carouselInner.appendChild(clonedChild);

            this.index++;

            if(this.index > this.maxElements - 1) {
                this.index = 0;
            } 

            this.itemsTargets.forEach((item) => {
                item.style.transition = '';
                item.style.transform = `translateX(${this.offset * 100}%)`;
            });

            // Translate all elements
            this.offset--;
        } else if (direction === 'prev') {
            this.index--;
    
            if(this.index < 0) {
                this.index = this.maxElements - 1;
            } 
    
            // Clone child
            const clonedChild = this.carouselClone.childNodes[this.index].cloneNode(true);
            this.carouselInner.insertBefore(clonedChild, this.carouselInner.firstChild);        
    
            this.offset--;
            this.itemsTargets.forEach((item) => {
                item.style.transition = '';
                item.style.transform = `translateX(${this.offset * 100}%)`;
            });
     
            this.offset++;
        }
    }
    
    scheduleAnimationAndCleanup() {
         setTimeout(() => {
            this.itemsTargets.forEach((item) => {
                item.style.transition = "transform .6s ease-in-out";
                item.style.transform = `translateX(${this.offset * 100}%)`;
            });
        }, 0);
    
        setTimeout(() => {
            this.cleanupAdditionalNodes();
            this.isAnimating = false;
        }, 600);
    }

    cleanupAdditionalNodes() {
        while (this.offset < 0) {
            this.itemsTargets[0].remove();
            this.offset++;
        }
        
        while (this.itemsTargets.length > this.maxElements) {
            this.itemsTargets[this.itemsTargets.length - 1].remove();
        }

        this.itemsTargets.forEach((item) => {
            item.style.transition= '';
            item.style.transform = '';
        }); 
    }

}
