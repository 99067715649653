import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['components', 'autocomplete', 'autocomplete-placeholder', 'geocode', 'location', 'location-id', 'latitude', 'longitude', 'bbne', 'bbsw', 'submit'];

  static get shouldLoad() {
    return window.railsEnvironment != 'test';
  }

  connect() {
    const context = this;
    this._addGoogleJStoDOM().then(() => {
      if (this.hasAutocompleteTarget) {
        this['autocomplete-placeholderTarget'].addEventListener('keydown', (evt) => {
          // if pressing enter we need to check if a location was selected
          // if it wasn't we will try and pick the first of the list of locations
          // that was returned by Google in the search
          if (evt.keyCode == 13) {
            // if we already have a location just submit
            if(this._searchLocationPresent()) {
              this.submitTarget.click();
            } else {
              const highlightedPlace = document.querySelector('.pac-item-selected');
              // if not don't let the for submit and select the first location
              // by simulating a keydown event
              evt.preventDefault();

              // only try to autoselect the first suggestion
              // if we aren't highlighting any yet
              if(!highlightedPlace) {
                let keyDownArrowEvent = new Event('keydown');
                keyDownArrowEvent.keyCode = 40;
                keyDownArrowEvent.which = keyDownArrowEvent.keyCode;
                this['autocomplete-placeholderTarget'].dispatchEvent(keyDownArrowEvent)
              }

              // if there was a location to be selected then submit the form
              this.element.addEventListener('place-selected', () => {
                this.submitTarget.click();
              })
            }
          } else {
            // if any other key than enter was pressed clear any
            // present bounding box
            this._clearSearchResults();
          }
        })

        this['autocomplete-placeholderTarget'].addEventListener('change', () => {
          if (this['autocomplete-placeholderTarget'].value == '') {
            this._clearSearchResults();
          }
        })

        var mappings = this.autocompleteTarget.dataset.mappings || {};
        if (typeof mappings === 'string') {
          mappings = JSON.parse(mappings);
        }
        context._GoogleAutocomplete(this['autocomplete-placeholderTarget'], mappings);
      };
      this.geocodeTargets.forEach((trigger) => {
        // we will assume that the `data-geocode` will contain the reference to the
        // input that we want to fill
        context.targets.findAll(trigger.dataset.output).forEach((input) => {
          // same as above we will look for a mappings attribute if we want to override
          // some of the defaults that we are supplying
          const mappings = input.dataset.mappings || {};
          if (typeof mappings === 'string') {
            mappings = JSON.parse(mappings);
          }

          // add a listener on the click of a button
          trigger.addEventListener('click', () => { context._GoogleReverseGeocode(input, mappings) });
        })
      });

      if (this.hasLocationTarget && this.locationTarget.getAttribute('data-use-location') != 'false') {
        context._GoogleReverseLocation(this.locationTarget);
      }
    });
  }

  _addGoogleJStoDOM() {
    // only add the JS code once
    if (!window.google) {
      let script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${window.googleMapsKey}&libraries=places&region=PT&callback=initAutocompleteDummy`;
      document.head.appendChild(script);
    }
    return new Promise(function (resolve, _reject) {
      (function waitForGoogle() {
        if (window.google) return resolve();
        setTimeout(waitForGoogle, 30);
      })();
    });
  }

  _searchLocationPresent() {
    return this.hasBbswTarget && (this.bbswTarget.value != '') && this.hasBbneTarget && (this.bbneTarget.value != '');
  }

  _clearSearchResults() {
    if (this.autocompleteTarget !== this['autocomplete-placeholderTarget']) this.autocompleteTarget.value = '';
    this.hasLatitudeTarget && (this.latitudeTarget.value = '');
    this.hasLongitudeTarget && (this.longitudeTarget.value = '');
    this.hasBbswTarget && (this.bbswTarget.value = '');
    this.hasBbneTarget && (this.bbneTarget.value = '');
    this['hasLocation-idTarget'] && (this['location-idTarget'].value = '');
  }

  _mapResults(place, input, fieldMappings) {
    let dataMapper = Object.assign({}, fieldMappings);
    // components to fetch and any transformation to apply to the
    // content
    let componentForm = {
      street_number: { field: 'short_name' },
      route: { field: 'long_name' },
      locality: { field: 'long_name' },
      administrative_area_level_1: { field: 'short_name' },
      postal_code: { field: 'short_name' }
    };

    if (place.address_components) {
      for (var i = 0; i < place.address_components.length; i++) {
        var components = place.address_components[i],
          addressType = null;

        // we need to loop through types and check which one we are dealing with and compare
        // against or componentForm mapping
        components.types.forEach((element) => {
          if (Object.keys(componentForm).includes(element)) {
            addressType = element;
          }
        });

        if (componentForm[addressType]) {
          var definition = componentForm[addressType];
          definition.value = components[definition.field];
        }
      }
    }
    for (const key in dataMapper) {
      var value = dataMapper[key];

      // now we will loop the fields that we obtained and try replace on value
      for (definition in componentForm) {
        value = value.replace('{' + definition + '}', componentForm[definition].value || '');
      }

      // we will overwrite any value there with the
      // new one that we can find here
      dataMapper[key] = value.trim();
    }

    if (dataMapper.address && dataMapper.address !== '') {
      input.value = dataMapper.address
    }
    // now we will set the values to the desired fields, basically we will filter
    // using a `data-address-component` attribute that will allow us to set the values
    // for each of the mappings that we have gathered
    for (const key in dataMapper) {
      const fields = this.componentsTargets.filter((el) => el.dataset.component == key);
      fields.forEach((field) => {
        field.value = dataMapper[key];
        // if (key == "postal_code" && dataMapper[key] != "") {
        //   let locality = this.getLocality(dataMapper[key]);
        //   locality.then(l => {
        //     if (l != "") {
        //       field.value = dataMapper[key] + " " + l;
        //     } else {
        //       field.value = dataMapper[key]
        //     }
        //   })

        // } else {
        //  field.value = dataMapper[key];
        // }
        field.dispatchEvent(new Event('change'))
      })
    }

    if (place.geometry) {
      const placeViewport = place.geometry.viewport;
      const sw = placeViewport.getSouthWest();
      const ne = placeViewport.getNorthEast();
      // it's time to apply geocode if we have any latitude/longitude field marked with
      // geocode `data-` attribute
      this.hasLatitudeTarget && (this.latitudeTarget.value = place.geometry.location.lat())
      this.hasLongitudeTarget && (this.longitudeTarget.value = place.geometry.location.lng())
      this.hasBbswTarget && (this.bbswTarget.value = `${sw.lat()},${sw.lng()}`)
      this.hasBbneTarget && (this.bbneTarget.value = `${ne.lat()},${ne.lng()}`)
    } else {
      // nothing was selected so reset all values
      this.hasLatitudeTarget && (this.latitudeTarget.value = '')
      this.hasLongitudeTarget && (this.longitudeTarget.value = '')
      this.hasBbswTarget && (this.bbswTarget.value = '')
      this.hasBbneTarget && (this.bbneTarget.value = '')
    }
    const placeSelected = new Event('place-selected')
    this.element.dispatchEvent(placeSelected)
  }

  _GoogleAutocomplete(input, mappings) {
    let types = []
    if (this.element.dataset.autocompleteType) types = [this.element.dataset.autocompleteType]

    const context = this;
    const options = {
      componentRestrictions: { country: ['pt'] }
    };

    var fieldMappings = {
      address: '{route} {street_number}',
      postal_code: '{postal_code} ',
      locality: '{locality}'
    };

    if (mappings) {
      fieldMappings = Object.assign(fieldMappings, mappings);
    }

    const autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.setFields(['address_components', 'name', 'geometry']);
    autocomplete.addListener('place_changed', function () {
      const place = autocomplete.getPlace();
      context.autocompleteTarget.value = input.value;
      context._mapResults(place, input, fieldMappings);
    });
  };

  _GoogleReverseGeocode(input, mappings) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const context = this;
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const geocoder = new google.maps.Geocoder;

        var fieldMappings = {
          address: '{route} {street_number}',
          postal_code: '{postal_code}',
          locality: '{locality}'
        };

        if (mappings) {
          fieldMappings = Object.assign(fieldMappings, mappings);
        }

        geocoder.geocode({ location: { lat: latitude, lng: longitude }, region: 'pt' }, (results, status) => {
          if (status === 'OK') {
            context._mapResults(results[0], input, fieldMappings);
          }
        })
      });
    }
  };

  _GoogleReverseLocation(input) {
    if ((input.value != '') ||
      (this.hasBbneTarget && this.bbneTarget.value != '') ||
      (this.hasBbswTarget && this.bbswTarget.value != '')) {
      // if the input has something inside don't
      // try and guess the user location
      // same applies if bounding boxes are filled
      return
    }
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const context = this;
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const geocoder = new google.maps.Geocoder();
        const matchingTypes = ['locality', 'administrative_area_level_2', 'administrative_area_level_1', 'country']

        geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
          if (status === 'OK') {
            const result = results.find(result => matchingTypes.some(v => result.types.indexOf(v) >= 0))
            input.value = result['address_components'][0]['long_name']
            context.autocompleteTarget.value = input.value;
            context._mapResults(result, input, {});
          }
        })
      });
    }
  }

  async getLocality(postalCode) {
    let response = await get(`/postal_code_localities?postal_code=${postalCode}`, { contentType: 'application/json' });

    if (response.ok) {
      let json = await response.json;
      return json.locality;
    }
  }
}
