import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.setEmailRequirement();
  }

  toggleRequiredField() {
    this.setEmailRequirement();
  }

  setEmailRequirement() {
    const emailField = document.getElementById("email-field");
    const emailFieldLabel = document.getElementById("email-label");
    const publicEmail = document.getElementById("public-email-fieldset");

    const checkbox = this.element;

    if (emailField) {
      emailField.required = !checkbox.checked;
      emailField.disabled = checkbox.checked;
      if (checkbox.checked) {
        emailField.value = "";
        publicEmail.classList.add("hide");
      } else {
        publicEmail.classList.remove("hide");
      }
    }

    if (emailFieldLabel) {
      if (checkbox.checked) {
        emailFieldLabel.classList.remove("asterisk-element");
        emailFieldLabel.classList.remove("asterisk-element--alert");
      } else {
        emailFieldLabel.classList.add("asterisk-element");
        emailFieldLabel.classList.add("asterisk-element--alert");
      }
    }
  }
}
