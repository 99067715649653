import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'selected' ]
  static values = { limit: Number }

  connect() {
    this.submitButton = this.element.querySelector('input[type="submit"]');

    this.element.addEventListener('change', this.change.bind(this));
    this.element.addEventListener('submit', this.submit.bind(this));
  }

  change() {
    let option  = this.element.querySelector('.selectable-option');
    let checked = this.element.querySelectorAll('.selectable-option:checked');

    if (checked.length <= 0) {
      this.submitButton.setAttribute('disabled', true);
    } else {
      this.submitButton.removeAttribute('disabled');
    }

    // update counter of selected images
    this.selectedTarget.innerHTML = checked.length;

    // if we are dealing with radio buttons we just skip the last validation of the limit
    // of items selected
    if (option && option.type == 'radio') {
      return;
    }

    // now we need to check the limit of images and if we are reaching
    // that value
    if (this.hasLimitValue) {
      let items = this.element.querySelectorAll('.selectable-option:not(:checked)');
      if (checked.length === this.limitValue) {
        items.forEach((item) => item.setAttribute('disabled', true));
      } else if (checked.length < this.limitValue) {
        items.forEach((item) => item.removeAttribute('disabled'));
      }
    }
  }

  submit() {
    var option = this.element.querySelector('.selectable-option:checked');
    // if we are dealing with radio buttons, we will add a hidden field for the filename
    // the field should be supplied through a `data-` attribute
    if (option && option.type == 'radio') {
      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'filename';
      input.value = option.dataset.filename;
      this.element.appendChild(input);
    }
  }
}
