import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "modal" ]

  connect() {
    document.addEventListener('click', this.outsideClickListener.bind(this));
  }

  disconnect() {
    document.removeEventListener('click', this.outsideClickListener.bind(this));
  }

  open(event) {
    console.log('open');
    event.stopPropagation();
    this.modalTarget.classList.toggle('d-none-important');
    document.getElementById('map').style.zIndex = 0;
  }

  close() {
    this.modalTarget.classList.toggle('d-none-important');
    document.getElementById('map').style.zIndex = "";
  }

  outsideClickListener(event) {
    if (this.modalTarget.contains(event.target)) return;
    if (!this.modalTarget.classList.contains('d-none-important')) {
      this.close();
    }
  }

  copyToClipboard(event) {
    navigator.clipboard.writeText(event.target.value).then(function() {
      console.log('Copying to clipboard was successful!');
    }, function(err) {
      console.error('Could not copy text: ', err);
    });
  }
}
