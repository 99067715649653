import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.setPrimaryPhoneRequirement();
  }

  toggleRequiredField() {
    this.setPrimaryPhoneRequirement();
  }

  setPrimaryPhoneRequirement() {
    const primaryPhoneField = document.getElementById("primary-phone-field");
    const primaryPhoneFieldLabel = document.getElementById(
      "primary-phone-label"
    );
    const publicPhone = document.getElementById("public-phone-fieldset");

    const checkbox = this.element;

    if (primaryPhoneField) {
      primaryPhoneField.required = !checkbox.checked;
      primaryPhoneField.disabled = checkbox.checked;
      if (checkbox.checked) {
        primaryPhoneField.value = "";
        publicPhone.classList.add("hide");
      } else {
        publicPhone.classList.remove("hide");
      }
    }

    if (primaryPhoneFieldLabel) {
      if (checkbox.checked) {
        primaryPhoneFieldLabel.classList.remove("asterisk-element");
        primaryPhoneFieldLabel.classList.remove("asterisk-element--alert");
      } else {
        primaryPhoneFieldLabel.classList.add("asterisk-element");
        primaryPhoneFieldLabel.classList.add("asterisk-element--alert");
      }
    }
  }
}
