import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mobile", "desktop"]

  connect() {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      this.mobileTarget.classList.remove('hide');
      this.desktopTarget.classList.add('hide');
    }
  }
}
