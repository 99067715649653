import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    interval: { type: Number, default: 0 }
  }

  connect() {
    if (this.intervalValue <= 0) return;
    
    this.timeout = setTimeout(() => {
      this.element.parentElement.reload();
    }, this.intervalValue * 1000);
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}
