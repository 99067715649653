import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "option", "methods", "template" ]

  connect() {
    this._renderPaymentMethods()
  }

  onChange() {
    this._renderPaymentMethods()
  }

  _renderPaymentMethods() {
    this.methodsTarget.innerHTML = ''
    const checked = this.optionTargets.find((option) => option.checked)
    if (!checked) return
    const types = JSON.parse(checked.dataset.types)
    const template = document.createRange().createContextualFragment(this.templateTarget.innerHTML)

    for (let key in types) {
      let element = template.cloneNode(true)
      // change the input value type
      let input = element.querySelector('.visually-hidden')
      let idForInput = input.name.replace(/\[|\]/gi, '_') + key
      input.value = key
      input.id = idForInput
      // update label to be accessible
      let label = element.querySelector('.clickable-card-label')
      label.setAttribute('for', idForInput)
      label.querySelector('h6').innerText = types[key]
      this.methodsTarget.appendChild(element)
    }

    // we just select the first method available
    const card = this.methodsTarget.querySelector('.clickable-card')
    card.querySelector('.clickable-card-label').click()
    this._showTotalBasedOnPaymentOption()
  }

  _showTotalBasedOnPaymentOption() {
    const options = document.querySelectorAll('[data-payment-type]')
    const selected = this.optionTargets.find((option) => option.checked)
    if (selected) {
      options.forEach((element) => {
        element.classList.toggle('hide', element.dataset.paymentType !== selected.value)
      })
    }
  }
}
