import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { timeToLive: Number } // In seconds

  connect() {
    if (this.hasTimeToLiveValue) {
      setTimeout(() => this.element.classList.add('hidden'), this.timeToLiveValue * 1000)
    }
  }

  show() {
    this.element.querySelectorAll(event.params['showSelector']).forEach((element) => {
      element.classList.remove('hidden')
    })
  }

  hide() {
    this.element.querySelectorAll(event.params['hideSelector']).forEach((element) => {
      element.classList.add('hidden')
    })
  }
}
