import 'cookieconsent';

 // code to be displayed on the app
 window.addEventListener("load", function() {
  window.cookieconsent.initialise({
    enabled: (window.railsEnvironment != 'test' || location.search.indexOf('cookieconsent=')>=0),
    palette: {
      popup: {
        background: "#1d1d1b",
        text: "#ffffff"
      },
      button: {
        background: "#ffda00",
        text: "#1d1d1b"
      }
    },
    law: {
      countryCode: 'PT',
      regionalLaw: true,
    },
    position: "bottom-left",
    content: {
      allow: "Permitir cookies",
      close: "&#x274c;",
      deny: "Rejeitar",
      dismiss: "Entendi!",
      header: "Cookies utilizados no website!",
      link: "Saber mais",
      message: "Este website utiliza cookies para garantir que obtém a melhor experiência. Ao continuar a navegar, concorda com o seu uso.",
      policy: "Política de Cookies",
      target: "_blank"
    }
  });
});
