import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { path: String }

  connect() {
    const mainCategorySelector = document.querySelector('[data-selector="maincategory"]');
    const subCategorySelector = document.querySelector('[data-selector="subcategory"]');

    subCategorySelector && subCategorySelector.addEventListener('change', () => {
      const selectedMainCategories = Array.from(mainCategorySelector.options).filter(option => option.selected).map(option => option.value);
      const selectedSubCategories = Array.from(subCategorySelector.options).filter(option => option.selected).map(option => option.value);
      const selectedCategories = selectedMainCategories.concat(selectedSubCategories);
      const selectedTags = Array.from(this.element.options).filter(option => option.selected).map(option => option.value);

      // send the selected categories so we get the possible tags
      // we will traverse the tree to get all possible tags
      let searchString = ["category_ids[]="];
      // concat the empty category array with any selected options
      searchString = searchString.concat(selectedCategories.map(id => `category_ids[]=${id}`));
      // create the query params and encode
      const idParams = encodeURI(searchString.join('&'));
      // const idParams = encodeURI(subSelectedCategories.map(id => `category_ids[]=${id}`).join('&'));

      this.getResults(`${this.pathValue}?${idParams}`, this.element.slim, selectedTags);
    })
  }

  async getResults(url, slim, selectedTags) {
    let response = await get(url, { contentType: 'application/json' });

    if (response.ok) {
      response.json.then((result) => {
        // for tags we are using then name as both value and text
        const values = Object.keys(result).map(key => ({
          value: result[key], text: result[key]
        }))
        if (values.length > 0) {
          // if we get any tags then update with the new tags
          // and restore the previously selected tags
          slim.setData(values)
          slim.set(selectedTags)
          slim.enable()
        } else {
          // no tags were returned!
          // disable the select too
          slim.setData([])
          slim.disable()
        }
      })
    }
  }
}


