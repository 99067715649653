import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "trigger", "message" ]

  toggle() {
    const isExpanded = this.triggerTarget.getAttribute('aria-pressed') === 'true'
    this.triggerTarget.setAttribute('aria-pressed', !isExpanded)
  }
}
