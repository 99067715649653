import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["usefulCount", "thankYouCount", "loveItCount", "ohCount"];

  toggleUseful(event) {
    this.toggleCounter(event, "useful", this.usefulCountTarget);
  }

  toggleThankYou(event) {
    this.toggleCounter(event, "thank_you", this.thankYouCountTarget);
  }

  toggleLoveIt(event) {
    this.toggleCounter(event, "love_it", this.loveItCountTarget);
  }

  toggleOh(event) {
    this.toggleCounter(event, "oh", this.ohCountTarget);
  }

  toggleCounter(event, reactionType, target) {
    event.preventDefault();
    const reviewId = this.element.dataset.reviewId;
    const button = event.currentTarget;
    const isActive = button.classList.contains('active-reaction');

    const requestBody = {
      reaction_type: reactionType,
      remove: isActive
    };

    fetch(`/reviews/${reviewId}/toggle_${reactionType}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify(requestBody)
    })
    .then(response => response.json())
    .then(data => {
      target.textContent = data[`${reactionType}_count`];
      button.classList.toggle('active-reaction');
    });
  }

}
