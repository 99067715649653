import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['starContainer', 'input']

  connect() {
    this.updateStars(this.inputTarget.value)
  }

  rate(event) {
    const rating = event.currentTarget.closest('[data-rating]').dataset.rating
    this.updateStars(rating)
    this.inputTarget.value = rating
  }

  hover(event) {
    const rating = event.currentTarget.closest('[data-rating]').dataset.rating
    this.updateStars(rating)
  }

  unhover(event) {
    this.updateStars(this.inputTarget.value)
  }

  updateStars(rating) {
    const starRating = parseInt(rating) || 0

    this.starContainerTargets.forEach((container, index) => {
      const unfilledStar = container.querySelector('.unfilled')
      const filledStar = container.querySelector('.filled')
      if (index < starRating) {
        unfilledStar.style.display = 'none'
        filledStar.style.display = 'inline'
      } else {
        unfilledStar.style.display = 'inline'
        filledStar.style.display = 'none'
      }
    })
  }
}
