import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "plan", "toggle", "pricing", "fees", "option" ]

  connect() {
    this.interval = null
    this._extractPeriods()

    if (this.hasPricingTarget && this.pricingTarget.value !== '') {
      this._selectByPricing()
    } else if (this.optionTargets.length > 0) {
      this._selectOption(this.optionTargets[0])
    }
  }

  toggle(event) {
    // remove the current pressed button
    if (this.pressedButton) this.pressedButton.setAttribute('aria-pressed', false)

    let button = event.currentTarget
    button.setAttribute('aria-pressed', true)

    this.interval = parseInt(button.dataset.interval)
    this._changePrices()
  }

  onPlanChange(event) {
    // remove any selected plan option
    this.planTargets.forEach((plan) => plan.classList.remove('selected'))
    this._selectOption(event.currentTarget)
  }

  onClick(event) {
    const clickedElement = event.target
    if (! clickedElement.classList.contains('play-button')) {
      const pricingElement = event.target.closest('.pricing')
      const radioElement = pricingElement.querySelector('input[type="radio"]')
      if (radioElement) radioElement.click()
    }
  }

  _extractPeriods() {
    const periods = []

    this.planTargets.forEach((plan) => {
      let options = JSON.parse(plan.dataset.options)
      options.forEach((option) => {
        let match = periods.find((period) => period.name === option.name)
        if (!match) {
          periods.push({ name: option.name, interval: option.interval_count })
        }
      })
    })

    this._buildButtonGroup(periods.sort((source, compare) => source.interval > compare.interval))
  }

  _buildButtonGroup(periods) {
    periods.forEach((period) => {
      let button = document.createElement('button')
      button.type = 'button'
      button.classList.add('button')
      button.setAttribute('aria-pressed', false)
      button.dataset.interval = period.interval
      button.innerText = period.name
      button.addEventListener('click', this.toggle.bind(this))
      this.toggleTarget.appendChild(button)
    })

    if (this.toggleTarget.childNodes.length) this.toggleTarget.childNodes[0].click()
  }

  _changePrices() {
    this.planTargets.forEach((plan) => {
      let options = JSON.parse(plan.dataset.options)
      if (options.length > 0) {
        let selected = options.find((option) => option.interval_count === this.interval)
        if (selected) {
          this._priceForPlan(plan, selected)
          this._setFooterLink(plan, selected)
        } else {
          this._disablePlan(plan)
        }
      }
    })

    let selected = this.planTargets.find((plan) => plan.classList.contains('selected'))
    if (selected) this._setPricing(selected)
  }

  _setPricing(card) {
    let selected = this._pricingOptionForPlan(card)
    if (selected) this.pricingTarget.value = selected.pricing
  }

  _setFooterLink(plan, selected) {
    const subscribeButton = plan.querySelector('[data-subscribe-url]')
    if (subscribeButton) {
      const pricingURL = subscribeButton.dataset.subscribeUrl.replace('__PRICING__', selected.pricing)
      subscribeButton.href = pricingURL
    }
  }

  _formattedAmount(amount) {
    return new Intl.NumberFormat(navigator.language, { style: 'decimal', maximumFractionDigits: 2 }).format(amount)
  }

  _pricingOptionForPlan(plan) {
    return this._optionsForPlan(plan).find((option) => option.interval_count === this.interval)
  }

  _optionsForPlan(plan) {
    return JSON.parse(plan.dataset.options)
  }

  _selectOption(option) {
    if (!option.checked) option.checked = true

    // let card = option.closest('.plan-option')
    let card = option.closest('.pricing')
    card.classList.add('selected')

    let selected = this._pricingOptionForPlan(card)
    this.pricingTarget.value = selected ? selected.pricing : ''

    this.feesTarget.value = 0
  }

  _selectByPricing() {
    const option = this.optionTargets.find((option) => option.checked)
    if (option) {
      const card = this.planTargets.find((card) => card.dataset.plan === option.value)
      const periods = JSON.parse(card.dataset.options)
      const selectedPeriod = periods.find((period) => period.pricing === this.pricingTarget.value)
      if(selectedPeriod) {
        const toggle = this.toggleTarget.querySelector(`button[data-interval="${selectedPeriod.interval_count}"]`)
        toggle.click()
      }
      this._selectOption(option)
    }
  }

  _priceForPlan(plan, option) {
    plan.classList.remove('disabled')

    const priceAmount = plan.querySelector('.pricing__cost .price__amount')
    if (priceAmount) priceAmount.innerHTML = this._formattedAmount(option.price_without_vat / option.interval_count)

    this._showPeriodNotice(plan, option.interval_count)

    if (option.interval_count !== 1) {
      this._showMonthlyPrice(plan)
    } else {
      this._cleanMonthlyPrice(plan)
    }
  }

  _disablePlan(plan) {
    plan.classList.add('disabled')
    if (plan.classList.contains('selected')) {
      plan.classList.remove('selected')
      let free = this.optionTargets.find((item) => item.dataset.plan === 'free')
      this._selectOption(free)
    }
  }

  _showPeriodNotice(plan, interval) {
    const periodNotice = plan.querySelector('.pricing__period')
    if (interval !== 1) {
      periodNotice.innerHTML = periodNotice.dataset.label.replace('__PERIOD__', interval)
    } else {
      periodNotice.innerHTML = periodNotice.dataset.monthlyLabel
    }
  }

  _showMonthlyPrice(plan) {
    const originalContainer = plan.querySelector('.pricing__original')
    const options = JSON.parse(plan.dataset.options)
    const monthlyPrice = options.find((option) => option.interval_count == 1 && option.interval === 'month')
    if (originalContainer && monthlyPrice) {
      const amount = this._formattedAmount(monthlyPrice.price_without_vat)

      originalContainer.innerHTML =
        `
        <span class="price">
          <span class="price__amount">${amount}</span><span class="price__currency">€</span>
        </span>
        `
    }
  }

  _cleanMonthlyPrice(plan) {
    const originalContainer = plan.querySelector('.pricing__original')
    if (originalContainer) originalContainer.innerHTML = ''
  }

  get pressedButton() {
    return Array.from(this.toggleTarget.childNodes).find((button) => button.getAttribute('aria-pressed') === 'true')
  }
}
