import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = [ "socialbutton", "form", "option" ]

  connect() {
    this.formTarget.classList.add('unavailable')
    if (this.selected) this.change()

    this.socialbuttonTargets.forEach((socialbutton) => {
      socialbutton.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.post(event.target.href);
      });
    })
  }

  change() {
    this.formTarget.classList.remove('unavailable')
    this.socialbuttonTargets.forEach((socialbutton) => {
      socialbutton.href = `${socialbutton.href.replace(/\?referrer=.*/,'')}?referrer=${this.selected.dataset.url}`;
    });
  }

  get selected() {
    return this.optionTargets.find((option) => option.checked)
  }

  post(to) {
    var myForm = document.createElement("form");
    myForm.method = "post";
    myForm.action = to;

    var fetchRequest = new FetchRequest("post", "");
    var myInput = document.createElement("input");
    myInput.setAttribute("name", "authenticity_token");
    myInput.setAttribute("value", fetchRequest.csrfToken);
    myForm.appendChild(myInput);

    document.body.appendChild(myForm);
    myForm.submit();
    document.body.removeChild(myForm);
  }
}
