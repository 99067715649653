import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['container']

  connect() {
    if (!this.hasContainerTarget) return;

    if (this.isMobile) {
      this.containerTarget.lastElementChild.scrollIntoView();
    } else {
      this.containerTarget.scrollTo(0, 99999999);
    }
  }

  focusIfMobile(event) {
    if (!this.isMobile) return;
    setTimeout( () => {
      const offsetY = window.scrollY + event.target.getBoundingClientRect().top - (event.target.getBoundingClientRect().height * 0.5)
      window.scrollTo({ top: offsetY, behavior: 'smooth' })
    }, 50)
  }

  get isMobile() {
    return window.matchMedia('(max-width: 1024px)').matches
  }
}
