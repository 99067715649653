import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]
  static values = { price: Number }

  connect() {
    this._displayCurrentPrice()
  }

  onQuantityChange() {
    this._displayCurrentPrice()
  }

  _displayCurrentPrice() {
    const priceElement = this.priceElement
    if (priceElement) {
      const quantity = parseInt(this.inputTarget.value)
      const total = (this.priceValue * quantity)
      priceElement.dataset.price = total
      // now we need to update the `.price__amount` value that handle the visual part
      const priceAmountElement = priceElement.querySelector('.price__amount')
      priceAmountElement.innerText = total.toFixed(2).replace('.', ',')

      // we will use this to communicate with any other element that needs to know when
      // the addon was updated
      const customEvent = new CustomEvent('calculator.update', { bubbles: true, detail: total })
      this.element.dispatchEvent(customEvent)
    }
  }

  get priceElement() {
    return this.element.querySelector('[data-price]')
  }
}
