import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['preview', 'source']

  updatePreview() {
    let imgPreview = this._findPreviewElement();

    if(!imgPreview) {
      return
    }

    const image = this.sourceTarget.files[0]
    if(image) {
      const reader = new FileReader()
      reader.onload = (data) => {
        imgPreview.src = data.target.result
        this.previewTarget.classList.remove('placeholder')
      }
      reader.readAsDataURL(image)
    }
  }

  _findPreviewElement() {
    let previewEl;
    if(this.previewTarget.tagName.toLowerCase() == 'img'){
      previewEl = this.previewTarget;
    }
    else if(this.previewTarget.querySelector('img')) {
      previewEl = this.previewTarget.querySelector('img')
    } else{
      let imgElm = document.createElement('img')
      this.previewTarget.appendChild(imgElm)
      previewEl = this.previewTarget.lastChild
    }
    return previewEl
  }
}
