import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "decrease", "increase" ]

  connect() {
    this._addButtons()
    this._checkCurrentValue()
  }

  disconnect() {
    const buttons = this.element.querySelectorAll('.quantity-selector__button')
    buttons.forEach((button) => button.remove())
  }

  doDecrease() {
    if (this.decreaseTarget.disabled) return

    const minimum = this.inputTarget.min
    const desiredValue = parseInt(this.inputTarget.value) - 1
    if (minimum && desiredValue < minimum) desiredValue = minimum
    this._setQuantity(desiredValue)
    this._checkDecreaseStatus()
  }

  doIncrease() {
    const maximum = this.inputTarget.max
    const desiredValue = parseInt(this.inputTarget.value) + 1
    if (maximum && desiredValue > maximum) desiredValue = maximum
    this._setQuantity(desiredValue)
    this._checkDecreaseStatus()
  }

  _addButtons() {
    // insert the decrease button before the input element
    this.inputTarget.insertAdjacentHTML('beforebegin',
      `<button type="button" data-quantity-selector-target="decrease" data-action="click->quantity-selector#doDecrease" class= "quantity-selector__button">-</button>`
    )

    // insert the increase button after the input element
    this.inputTarget.insertAdjacentHTML('afterend',
      `<button type="button" data-quantity-selector-target="increase" data-action="click->quantity-selector#doIncrease" class= "quantity-selector__button">+</button>`
    )
  }

  _setQuantity(value) {
    this.inputTarget.value = value
    this.inputTarget.dispatchEvent(new Event('change'))
  }

  _checkDecreaseStatus() {
    const minimum = parseInt(this.inputTarget.min)
    const currentValue = this.inputTarget.valueAsNumber
    this.decreaseTarget.disabled = currentValue === minimum
  }

  _checkCurrentValue() {
    const minimum = parseInt(this.inputTarget.min)
    const currentValue = this.inputTarget.valueAsNumber
    if (currentValue < minimum) this.inputTarget.value = minimum
    this._checkDecreaseStatus()
  }
}
