import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "section"]

  initialize() {
    if (this.hasSectionTarget) this.onScroll = this._spyScroll.bind(this)
  }

  connect() {
    if (this.hasMenuTarget && this.hasSectionTarget) {
      this.sectionTargets.forEach((section) => {
        const link = document.createElement('a')
        link.href = `#${section.id}`
        link.innerText = section.dataset.label || section.querySelector('.section-title').innerText
        link.dataset.action = `${this.scope.identifier}#onClick`
        this.menuTarget.appendChild(link)
      })
      this.menuTarget.childNodes[0] && this.menuTarget.childNodes[0].classList.add('active')
    }

    // activate the first menu item
    window.addEventListener('scroll', this.onScroll)
  }

  disconnect() {
    window.removeEventListener('scroll', this.onScroll)
    if (this.hasMenuTarget) {
      this.menuTarget.innerHTML = '';
    }
  }

  onClick(event) {
    event.preventDefault()
    const element = event.target
    const url = new URL(element.href)
    const target = document.querySelector(url.hash)

    // remove any active link
    this._removeActive()
    target.classList.add('active')

    if (element) {
      window.scrollTo({ top: target.offsetTop, behavior: 'smooth' })
    }
  }

  _spyScroll() {
    const position = document.documentElement.scrollTop || document.body.scrollTop
    const sections = this.sectionTargets.filter((section) => {
      return section.offsetTop <= position
    })

    this._removeActive()
    let section = this.sectionTargets[0]
    if (sections.length > 0) section = sections.pop()
    if (this.hasMenuTarget) {
      this.menuTarget.querySelector(`a[href*=${section.id}]`).classList.add('active')
    }
  }

  _removeActive() {
    if (this.hasMenuTarget) {
      this.menuTarget.querySelectorAll('.active').forEach((element) => element.classList.remove('active'))
    }
  }
}
