import { Controller } from "@hotwired/stimulus"
import Elements from "../../payments/elements"

export default class extends Controller {
  static targets = [ "card", "holder", "feedback", "tokenizer", "submit" ]
  static values = { secret: String }

  static get shouldLoad() {
    return window.railsEnvironment != 'test';
  }

  connect() {
    this.stripe = Stripe(window.stripeKey, { locale: 'pt' })
    this.elements = this.stripe.elements()
    this.card = this.elements.create('card', { style: Elements.styles() })
    this.card.mount(this.cardTarget)
    this.card.addEventListener('change', this.onChange.bind(this))
    this.submitTarget.disabled = true
    this.isValid = false
  }

  onChange(card) {
    if (card.complete) {
      this.feedbackTarget.innerText = ''
      this.submitTarget.disabled = false
      this.isValid = true
    } else if (card.error) {
      this.feedbackTarget.innerText = card.error.message
      this.submitTarget.disabled = true
      this.isValid = false
    }
  }

  onSubmit(event) {
    event.preventDefault()

    if (this.isValid) {
      this.stripe.confirmCardSetup(
        this.secretValue,
        {
          payment_method: {
            card: this.card,
            billing_details: { name: this.cardName }
          }
        }
      ).then((result) => {
        if (result.error) {
          this.feedbackTarget.innerText = result.error.message
        } else {
          this.tokenizerTarget.value = result.setupIntent.id
          this.element.submit()
        }
      })
    }
  }

  get cardName() {
    return this.holderTarget.value
  }
}
