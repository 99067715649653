import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = [ 'handle', 'form' ]

  connect() {
    this.handleTargets.forEach((handle) => {
      handle.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
      })
      handle.style.display = 'block'
    })

    new Sortable(this.element, {
      handle: '.handle',
      onUpdate: (event) => {
        const handle = event.item.querySelector('.handle');
        const position = event.newDraggableIndex + 1;

        // don't do nothing in case of form unavailable
        if (!handle) return;

        // now we replace the placeholder __POSITION__ by the real position
        handle.setAttribute('action', handle.action.replace('__POSITION__', position));
        handle.requestSubmit();
      }
    })
  }
}
