import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select";
import { get } from '@rails/request.js'

export default class extends Controller {
  connect() {
    let options = { select: this.element };
    // console.log(options)
    if (window.railsEnvironment == 'test') {
      options = Object.assign(options, { timeoutDelay: 0 });
    }
    if (this.element.dataset.addable) {
      options = Object.assign(options, { addable: function (value) { return value } });
    }
    if (this.element.dataset.placeholder) {
      options = Object.assign(options, { placeholder: this.element.dataset.placeholder });
    }
    if (this.element.dataset.searchPlaceholder) {
      options = Object.assign(options, { searchPlaceholder: this.element.dataset.searchPlaceholder });
    }

    if (this.element.disabled) {
      options = Object.assign(options, { disabled: true });
    }
    if (this.element.dataset.searchText) {
      options = Object.assign(options, { searchText: this.element.dataset.searchText })
    }
    if (this.element.dataset.searchPlaceholder) {
      options = Object.assign(options, { searchPlaceholder: this.element.dataset.searchPlaceholder })
    }
    if (this.element.dataset.searchingText) {
      options = Object.assign(options, { searchingText: this.element.dataset.searchingText })
    }
    if (this.element.id === 'localidades-select') {
      options = Object.assign(options, { limit: 3 })
    }
    if (this.element.dataset.remote) {
      options = Object.assign(options, {
        ajax: (search, callback) => {
          if (search.length < 2) {
            callback(false)
          } else {
            this.getResults(`${this.element.dataset.url}?${encodeURI(`search[name]=${encodeURIComponent(search)}`)}`, callback);
          }
        }
      });
    }

    const slimSelect = new SlimSelect(options);
    // If the select has the required attribute we need to override
    // the default display value that slimselectjs applies
    // We also have to change the parentElement position
    // because we will apply a position: absolute to the select
    if (this.element.required) {
      this.element.parentElement.style.position = 'relative';
      this.element.style.display = 'block';
      this.element.style.position = 'absolute';
      this.element.style.clip = 'rect(0, 0, 0, 0)';
      this.element.style.pointerEvents = 'none';
    }
    // if we can add elements we want to be able to use the
    // enter key to add instead of clicking the icon
    // unfortunately we have to redo this check here
    // we can't do this on the block above since slimselect
    // wasn't initialized yet
    if (this.element.dataset.addable) {
      const searchField = slimSelect.slim.container.querySelector('input[type="search"]')
      const addIcon = slimSelect.slim.container.querySelector('.ss-addable')
      searchField.addEventListener('keydown', (event) => {
        // only add if there is nothing on the list to select
        if ((event.key == 'Enter' || event.keyCode == 13) && slimSelect.data.filtered.length == 0) {
          addIcon.click()
        }
      })
    }

  }

  async getResults(path, callback) {
    let results = {}
    let response = await get(path, { contentType: 'application/json' })

    if (response.ok) {
      response.json.then((result) => {
        const values = result.map(option => ({ value: option[this.element.dataset.field] || option.id, text: option.name || option.title, url: option.url }))
        values.map(options => results[options.value] = options)
        callback(values)
      })
    } else {
      callback(false)
    }
  }
}
