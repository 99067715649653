import { Controller } from "@hotwired/stimulus"
import ProcessingOverlay from "../../managers/processing_overlay"

export default class extends Controller {
  static values = { minSize: Number }

  connect() {
    this.element.querySelector('input[type="file"]').addEventListener('change', (e) => {
      const files = e.target.files;
      const requiredSize = this.minSizeValue;
      let allImagesValid = true;

      const checkImage = (file, callback) => {
          if (requiredSize && file.type.startsWith('image/')) {
              const reader = new FileReader();
  
              reader.onload = function(event) {
                  const img = new Image();
                  img.onload = function() {
                      if (this.width < requiredSize || this.height < requiredSize) {
                          allImagesValid = false;
                      }
                      callback(); 
                  };
                  img.src = event.target.result;
              };
              reader.readAsDataURL(file);
          } else {
            callback();
          }
      };
  
      const processImages = (index = 0) => {
          if (index < files.length) {
              checkImage(files[index], () => processImages(index + 1));
          } else {
              if (allImagesValid) {
                ProcessingOverlay.show();
                this.element.requestSubmit();
              } else {
                alert('Uma ou mais imagens não cumprem o requisito de largura. \nO minimo é 512x512 pixeis.');
              }
          }
      };

      processImages();
    })
  }

  disconnect() {
    ProcessingOverlay.hide();
  }
}
