import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "region", "district" ]

  checkRegion() {
    this.districtTargets.forEach((district) => { district.checked = false})
  }

  checkDistrict() {
    this.regionTargets.forEach((region) => { region.checked = false})
  }
}
