import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox" ];
  static values = { limit: { type: Number, default: 4 } }

  check(event) {
    if (this.checkboxTargets.filter((c) => c.checked).length > this.limitValue) {
      event.target.checked = false;
    }
  }
}
