import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  onChange(event) {
    const target = event.currentTarget
    const compare = this.inputTargets.filter((input) => input !== target)
    if (compare.length > 0) {
      const match = compare.find((input) => {
        let wrapper = input.closest('.social-account')
        let isDeleted = parseInt(wrapper.querySelector('[name*=_destroy]').value) === 1
        return input.value === target.value && !isDeleted
      })
      if (match) {
        alert(this.repeatedMessage)
        target.value = ''
      }
    }
  }

  get repeatedMessage() {
    return this.data.get('repeated') || 'O link já existe na lista'
  }
}
