import { Controller } from "@hotwired/stimulus"
import { CountUp } from 'countup.js';

export default class extends Controller {
    static targets = [ 'value' ]
    
    static get shouldLoad() {
        return window.railsEnvironment != 'test';
    }

    connect() {
        this.animationTriggered = false;
        window.addEventListener('scroll', this.onScroll.bind(this))
    }
  
    disconnect() {
        window.removeEventListener('scroll', this.onScroll.bind(this))
    }

    onScroll() {   
        if (this.isElementInViewport(this.valueTargets[0]) && !this.animationTriggered ) {
            this.animationTriggered = true;
            this.valueTargets.forEach((target) => {
                const eventValue = target.getAttribute('data-event-value')
                const decimalPlaces = target.getAttribute('data-decimal-places')
                
                const countUp = new CountUp(target, Number(eventValue), { decimalPlaces: Number(decimalPlaces)});
    
                if (!countUp.error) {
                    countUp.start();
                } else {
                    console.error(countUp.error);
                }
            });
        }
    }

    isElementInViewport(el) {
        var rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}