import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  openShareDialog(event) {
    
    var width = 600;
    var height = 400;

    var left = (window.innerWidth - width) / 2;
    var top = (window.innerHeight - height) / 2;

    var shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(event.params.url);

    window.open(shareUrl, 'Share on Facebook', 'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top);
  }
}
