import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get shouldLoad() {
    return false && window.railsEnvironment != 'test';
  }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) return;

    window.googletag.cmd.push(function() {
      window.googletag.pubads().clear();
    });

    var verticals = JSON.parse(this.data.get("verticals"));

    if (window.googletag.pages.user) {
      window.googletag.cmd.push(function() {
        window.googletag.pubads()
             .setTargeting('vertical', verticals)
             .setTargeting('tags-vertical', 'my_tags_vertical')
             .setTargeting('categoria', 'my_category')
             .setTargeting('location', 'my_location');

        window.googletag.pubads().refresh([
          window.googletag.slots['user_slot1'],
          window.googletag.slots['user_slot2']
        ]);
      });
    } else {
      window.googletag.cmd.push(function() {
        window.googletag.slots['user_slot1'] = window.googletag.defineSlot('/21858406487/pag-amarelas/user-profile-page/banner', [[320,50]], 'div-gpt-user-banner')
                 .setTargeting('position', ['banner'])
                 .defineSizeMapping(window.googletag.sizes.mapBanner)
                 .addService(window.googletag.pubads());

        window.googletag.slots['user_slot2'] = window.googletag.defineSlot('/21858406487/pag-amarelas/user-profile-page/side', [[300,250]], 'div-gpt-user-side')
                 .setTargeting('position', ['side'])
                 .defineSizeMapping(window.googletag.sizes.mapSide)
                 .addService(window.googletag.pubads());

        window.googletag.pubads()
           .setTargeting('vertical', verticals)
           .setTargeting('tags-vertical', 'my_tags_vertical')
           .setTargeting('categoria', 'my_category')
           .setTargeting('location', 'my_location');

        window.googletag.enableServices();

        window.googletag.display("div-gpt-user-banner");
        window.googletag.display("div-gpt-user-side");

        window.googletag.pages.user = true;
      });
    }
  }
}
