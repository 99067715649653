import { Controller } from "@hotwired/stimulus"
import ProcessingOverlay from "../../managers/processing_overlay"

export default class extends Controller {
  static targets = [ "secret", "option", "submit" ]

  static get shouldLoad() {
    return window.railsEnvironment != 'test';
  }

  connect() {
    this.submitTarget.setAttribute('disabled', 'disabled')

    if (typeof Stripe !== 'undefined') {
      this._load()
    } else {
      setTimeout(this._load.bind(this), 1000)
    }
  }

  onSubmit(event) {
    event.preventDefault()
    const selectedPaymentMethod = this.paymentMethod
    if (selectedPaymentMethod) {
      ProcessingOverlay.show(this.overlayFeedback)

      if (this.stripe) {

        let stripeMethod;
        if (selectedPaymentMethod.id.startsWith("sepa")) {
          stripeMethod = this.stripe.confirmSepaDebitPayment;
        } else {
          stripeMethod = this.stripe.confirmCardPayment;
        }

        stripeMethod(this.secretTarget.value, {
          payment_method: selectedPaymentMethod.value
        }).then((result) => {
          if (result.error) {
            ProcessingOverlay.hide()
          } else {
            // we will append the `paymentIntent` for reference, we probably will
            // not use it in server because we will introspect the subscription itself
            const input = document.createElement('input')
            input.type = 'hidden'
            input.name = 'token'
            input.value = result.paymentIntent.id
            this.element.appendChild(input)
            this.element.requestSubmit()
          }
        });

      } else {
        this.element.requestSubmit()
      }
    }
  }

  _load() {
    this.stripe = Stripe(window.stripeKey, { locale: 'pt' })
    this.submitTarget.removeAttribute('disabled')
  }

  get paymentMethod() {
    return this.optionTargets.find((option) => option.checked)
  }

  get overlayFeedback() {
    return this.data.get('feedback') || 'A processar ...'
  }
}
