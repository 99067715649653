import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
import SlimSelect from "slim-select";

export default class extends Controller {
  static targets = ['listingSelect', 'categorySelect', 'articleSelect', 'editor']
  static values = { listingsPath: String, categoriesPath: String, articlesPath: String }

  connect() {
    this._createSelect(this.listingSelectTarget, 'listings')
    this._createSelect(this.categorySelectTarget, 'categories')
    this._createSelect(this.articleSelectTarget, 'articles')
  }

  _createSelect(element, type) {
    let results = {};
    new SlimSelect({
      select: element,
      placeholder: "Selecionar",
      searchText: "Sem resultados",
      searchPlaceholder: "Pesquisa",
      searchingText: "A pesquisar, por favor aguarde",
      ajax: (search, callback) => {
        // Perform your own ajax request here
        if (search.length < 2) {
          callback(false)
        } else {
          this.getResults(`${this[`${type}PathValue`]}?${encodeURI(`search[name]=${search}`)}`, results, callback);
        }
      },
      onChange: (info) => {
        let linkElement = document.createElement('a');
        // currently Trix simply removes any data attributes that we add
        // but we might need those in the future
        // this will still be here
        linkElement.dataset.id = info.value;
        linkElement.dataset.linkbuilding = true;
        linkElement.dataset.type = type;
        linkElement.href = results[info.value].url
        linkElement.innerText = info.text

        this.editorTarget.editor.insertHTML(linkElement.outerHTML);
        this.editorTarget.editorController.toolbarController.hideDialog();
        results = {};
        element.slim.close();
      }
    })
  }

  async getResults(path, results, callback) {
    let response = await get(path, { contentType: 'application/json' });

    if (response.ok) {
      response.json.then((result) => {
        const values = result.map(option => ({ value: option.id, text: option.name || option.title, url: option.url }))
        values.map(options => results[options.value] = options);
        callback(values)
      })
    } else {
      callback(false)
    }
  }
}
