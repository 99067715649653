import { Controller } from "@hotwired/stimulus"
import Tobii from '@midzer/tobii'

export default class extends Controller {
  connect() {
    if (!this.tobii) {
      this.tobii = new Tobii({ zoom: false, captions: false });
    }
  }

  disconnect() {
    if (this.tobii) {
      this.tobii.close();
      this.tobii = null;
    }
  }
}
