import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
import { CountUp } from 'countup.js';

export default class extends Controller {
  static targets = [ 'value', 'comparisonValue' ]
  static values = { path: String, dateStart: String, dateEnd: String }

  connect() {
    document.querySelector('.spinner').classList.remove('hide');
    this.getResults(`${this.pathValue}?${new URLSearchParams({start_date: this.dateStartValue, end_date: this.dateEndValue })}`);
  }

  async getResults(path) {
    let response = await get(path, { contentType: 'application/json' });

    if (response.ok) {
      response.json.then((result) => {
        document.querySelector('.spinner').classList.add('hide');
        // Value Counters
        this.valueTargets.forEach((target) => {

          const countUp = new CountUp(target, result["stats"][target.getAttribute('data-event-type')]);
          if (!countUp.error) {
            countUp.start();
          } else {
            console.error(countUp.error);
            console.log(result["stats"][target.getAttribute('data-event-type')])
          }
        });
        let params = new URLSearchParams(window.location.search);

        if (params.has('compare')) {
          this.comparisonValueTargets.forEach((target) => {
          target.classList.remove('hide');

          const countUp = new CountUp(target, result["comparison_stats"][target.getAttribute('data-event-type')]);
          if (!countUp.error) {
            countUp.start();
          } else {
            console.error(countUp.error);
          }
        });
        } 



        let hasYext = document.getElementById("graph.listings");
        if (hasYext) {
          document.getElementById("graph.listings").parentElement.innerHTML = '<canvas id="graph.listings"></canvas>';
          document.getElementById("graph.reviews").parentElement.innerHTML = '<canvas id="graph.reviews"></canvas>';

          let canvasListings = document.getElementById('graph.listings').getContext('2d');
          let canvasReviews = document.getElementById('graph.reviews').getContext('2d');

          canvasListings = new Chart (canvasListings, {type: "line", data: result["stats"]['graph.listings.data']});
          canvasReviews = new Chart (canvasReviews, {type: "line", data: result["stats"]['graph.reviews.data']});

          canvasListings.update();
          canvasReviews.update();

          /// Conditional facebook graph
          let graphFacebook = document.getElementById("graph.facebook")

          if (graphFacebook) {
            graphFacebook.parentElement.innerHTML = '<canvas id="graph.facebook"></canvas>';
            let canvasFacebook = document.getElementById('graph.facebook').getContext('2d');
            canvasFacebook = new Chart (canvasFacebook, {type: "line", data: result['graph.facebook.data']});
            canvasFacebook.update();
          }
        }

      })
    }
  }
}
