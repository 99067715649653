import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "list", "toggleText", "preview" ]

  toggle(event) {
    this.listTarget.classList.toggle('d-none');
    if (this.previewTarget) {
      this.previewTarget.classList.toggle('d-none-important');
    }
    if (this.toggleTextTarget.textContent === 'Mostrar mais') {
      this.toggleTextTarget.textContent = 'Mostrar menos';
    } else {
      this.toggleTextTarget.textContent = 'Mostrar mais';
    }


    // Hide the clicked button element
    this.toggleTextTarget.classList.add('d-none-important');
  }
}
