import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['previewContainer', 'fileField'];

  connect() {
    this.previewContainerTarget.addEventListener('click', this.openFileDialog.bind(this));
    this.fileFieldTarget.addEventListener('change', this.handleFileUpload.bind(this));
  }

  openFileDialog() {
    this.fileFieldTarget.click();
  }

  handleFileUpload(event) {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Clear any existing preview
        this.previewContainerTarget.innerHTML = '';

        // Create and add new preview image
        const preview = document.createElement('img');
        preview.setAttribute('src', e.target.result);
        preview.classList.add('image-preview');
        this.previewContainerTarget.appendChild(preview);
      };

      reader.readAsDataURL(file);
    } else {
      // If no file is selected (e.g., user cancelled the file dialog)
      this.previewContainerTarget.innerHTML = '';
    }
  }
}
