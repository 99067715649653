import { Controller } from "@hotwired/stimulus"
import * as L from 'leaflet/dist/leaflet-src.esm';

export default class extends Controller {
  static values = {
    maptilerKey: String, type: String, unselectedIcon: String, selectedIcon: String,
    boundingBox: Object, geoShape: Object, latitude: Number, longitude: Number
  }

  static get shouldLoad() {
    return window.railsEnvironment != 'test';
  }

  connect() {
    this.mapCenter   = ["38.716946", "-9.142685"];
    this.zoomLevel   = 16;
    this.iconSize    = [31, 31];
    this.smallIconSize = [this.iconSize[0] / 1.5, this.iconSize[1] / 1.5]
    this.iconAnchor  = [15.5, 31];
    this.popupAnchor = [0, -this.iconSize[1]]; // use the height of the icon size to define the popup anchor position

    this.searchForm   = document.querySelector('#search');
    this.searchButton = document.querySelector('#map-research')

    this.mapPin = L.icon({
      iconUrl: this.unselectedIconValue,
      iconSize: this.iconSize,
      iconAnchor: this.iconAnchor,
      popupAnchor: this.popupAnchor,
      className: 'map-pin'
    });

    this.mapSmallPin = L.icon({
      iconUrl: this.unselectedIconValue,
      iconSize: this.smallIconSize,
      iconAnchor: this.iconAnchor,
      popupAnchor: this.popupAnchor,
      className: 'map-small-pin'
    });

    this.map = L.map(this.element);

    // define the maptiles and attribution to use
    L.tileLayer(`https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=${this.maptilerKeyValue}`, {
      attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>',
      maxZoom: 18
    }).addTo(this.map);

    this[`${this.typeValue}Map`]();
  }

  listingMap() {
    const pin = [this.latitudeValue, this.longitudeValue]
    L.marker(pin, { icon: this.mapPin }).addTo(this.map);
    this.map.setView(pin, this.zoomLevel)
  }

  locationMap() {
    if (this.boundingBoxIsSinglePoint() && !this.hasGeoShapeValue) {
      const marker = [this.boundingBoxValue.boundingBoxSouthwestLatitude, this.boundingBoxValue.boundingBoxSouthwestLongitude];
      L.marker(marker, { icon: this.mapPin }).addTo(this.map);
      this.map.setView(marker, this.zoomLevel)
    } else if (this.isCompleteBoundingBox()) {
      const bounds = this.returnArrayBounds()
      L.rectangle(bounds, { color: "#ff7800", weight: 1 }).addTo(this.map);
      this.map.fitBounds(bounds);
    } else if (this.hasGeoShapeValue) {
      const shapeStyle = { "color": "#ff7800", "weight": 2, "opacity": 0.65 };
      const feature = L.geoJSON(this.geoShapeValue, shapeStyle).addTo(this.map);
      this.map.fitBounds(feature.getBounds());
    }
  }

  searchableMap() {
    // Render big markers for 1st page results and small markers for 2nd page results
    document.querySelectorAll('[data-map-marker]').forEach((e) => this.addMarker(e, this.mapPin));
    document.querySelectorAll('[data-map-marker-next]').forEach((e) => this.addMarker(e, this.mapSmallPin));

    this.searchButton.addEventListener('click', this.search.bind(this));
    this.map.addEventListener('moveend', this.move.bind(this));
    this.map.addEventListener('popupopen', (event) => event.popup._source._icon.src = this.selectedIconValue);
    this.map.addEventListener('popupclose', (event) => {
      event.popup._source._icon.src = this.unselectedIconValue;
      this.highlightResult();
    });

    if (this.isCompleteBoundingBox()) {
      this.map.fitBounds(this.returnArrayBounds());
    } else {
      this.map.setView(this.mapCenter, this.zoomLevel)
    }
  }

  search(event) {
    const bounds = this.map.getBounds();
    this.searchForm.elements.search_ne.value = [bounds._northEast.lat, bounds._northEast.lng]
    this.searchForm.elements.search_sw.value = [bounds._southWest.lat, bounds._southWest.lng]
    this.searchForm.elements.search_map.value = true
    this.searchForm.requestSubmit();
  }

  move(event) {
    this.searchButton.classList.remove('hide');
    this.searchForm.elements.search_location.value = '';
    this.searchForm.elements.search_location_id.value = '';
  }

  // If both latitudes and longitudes are the same then it is a single point
  boundingBoxIsSinglePoint() {
    return (this.boundingBoxValue.boundingBoxSouthwestLatitude == this.boundingBoxValue.boundingBoxNortheastLatitude) && (this.boundingBoxValue.boundingBoxSouthwestLongitude == this.boundingBoxValue.boundingBoxNortheastLongitude)
  }

  // Return an array with the bounding box values
  returnArrayBounds() {
    return [
      [this.boundingBoxValue.boundingBoxSouthwestLatitude, this.boundingBoxValue.boundingBoxSouthwestLongitude],
      [this.boundingBoxValue.boundingBoxNortheastLatitude, this.boundingBoxValue.boundingBoxNortheastLongitude]
    ]
  }

  // Returns true if a bounding box has all corners defined
  isCompleteBoundingBox() {
    return !!(this.boundingBoxValue.boundingBoxSouthwestLatitude && this.boundingBoxValue.boundingBoxSouthwestLongitude && this.boundingBoxValue.boundingBoxNortheastLatitude && this.boundingBoxValue.boundingBoxNortheastLongitude)
  }

  addMarker(element, icon) {
    if (element.dataset.latitude && element.dataset.longitude) {
      const marker = L.marker([element.dataset.latitude, element.dataset.longitude], { icon: icon }).addTo(this.map);
      const customPopup = `<turbo-frame id="card-${element.dataset.id}" src="/paginas/${element.dataset.id}/popover" />`;

      marker.bindPopup(customPopup, { minWidth: 300, maxWidth: 300, closeButton: false })
      marker.addEventListener('click', (event) => {
        this.map.panTo(event.target.getLatLng());
        this.highlightResult(element);
      })
    }
  }

  highlightResult(element = null) {
    document.querySelectorAll('.listing.highlight').forEach((s) => s.classList.remove('highlight'))
    if (element) element.classList.add('highlight');
  }
}
