import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get shouldLoad() {
    return false && window.railsEnvironment != 'test';
  }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) return;

    window.googletag.cmd.push(function() {
      window.googletag.pubads().clear();
    });

    if (window.googletag.pages.home) {
      window.googletag.cmd.push(function() {
        window.googletag.pubads()
           .setTargeting('vertical', 'my_vertical')
           .setTargeting('tags-vertical', 'my_tags_vertical')
           .setTargeting('categoria', 'my_category')
           .setTargeting('location', 'my_location');

        window.googletag.pubads().refresh([
          window.googletag.slots['home_slot1'],
          window.googletag.slots['home_slot2'],
          window.googletag.slots['home_slot3']
        ]);
      });
    } else {
      window.googletag.cmd.push(function() {
        window.googletag.slots['home_slot1'] = window.googletag.defineSlot('/21858406487/pag-amarelas/homepage/banner-top', [[320,50]], 'div-gpt-home-banner-top')
             .setTargeting('position', ['banner-top'])
             .defineSizeMapping(window.googletag.sizes.mapBanner)
             .addService(window.googletag.pubads());

        window.googletag.slots['home_slot2'] = window.googletag.defineSlot('/21858406487/pag-amarelas/homepage/slider', [[1,1],[300,250]], 'div-gpt-home-slider')
               .setTargeting('position', ['slider'])
               .addService(window.googletag.pubads());

        window.googletag.slots['home_slot3'] = window.googletag.defineSlot('/21858406487/pag-amarelas/homepage/banner-bottom', [[320,150]], 'div-gpt-home-banner-bottom')
               .setTargeting('position', ['banner-bottom'])
               .defineSizeMapping(window.googletag.sizes.mapBottom)
               .addService(window.googletag.pubads());

        window.googletag.pubads()
           .setTargeting('vertical', 'my_vertical')
           .setTargeting('tags-vertical', 'my_tags_vertical')
           .setTargeting('categoria', 'my_category')
           .setTargeting('location', 'my_location');

        window.googletag.enableServices();

        window.googletag.display("div-gpt-home-banner-top");
        window.googletag.display("div-gpt-home-slider");
        window.googletag.display("div-gpt-home-banner-bottom");

        window.googletag.pages.home = true;
      });
    }
  }
}
