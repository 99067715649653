import { Controller } from "@hotwired/stimulus"
import Elements from "../../payments/elements"
import ProcessingOverlay from "../../managers/processing_overlay"

export default class extends Controller {
  static targets = [ "view", "card", "submit", "reusable", "errors" ]

  connect() {
    this.element.hidden = true
    if (typeof Stripe !== 'undefined') {
      this._load()
    } else {
      setTimeout(this._load.bind(this), 1000)
    }
  }

  onChange(card) {
    if (card.complete) {
      this.submitTarget.disabled = false
      this.errorsTarget.hidden = true
    } else if (card.error) {
      this.submitTarget.disabled = true
      this.errorsTarget.innerText = card.error.message
      this.errorsTarget.hidden = false
    }
  }

  onSubmit() {
    ProcessingOverlay.show(this.overlayFeedback)
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card
    }).then((result) => {
      if (result.error) {
        ProcessingOverlay.hide()
        this.errorsTarget.hidden = false
        this.errorsTarget.innerText = result.error.message
      } else {
        fetch(this.prepareUrl, this._paramsForRequest({ payment_method_id: result.paymentMethod.id }))
          .then((result) => {
            result.json().then((json) => this._handleServerResponse(json))
          }
        )
      }
    })
  }

  reuseCard() {
    const card = this.cardTargets.find((option) => option.checked)
    if (!card) return

    ProcessingOverlay.show(this.overlayFeedback)
    fetch(this.prepareUrl, this._paramsForRequest({ payment_method_id: card.value }))
      .then((result) => {
        result.json().then((json) => this._handleServerResponse(json))
      }
    )
  }

  _load() {
    this.element.hidden = false
    this.stripe = Stripe(window.stripeKey, { locale: 'pt' })
    this.elements = this.stripe.elements()
    this.card = this.elements.create('card', { style: Elements.styles() })
    this.card.mount(this.viewTarget)
    this.card.addEventListener('change', this.onChange.bind(this))
    this.submitTarget.dataset.action = `${this.scope.identifier}#onSubmit`
    this.submitTarget.disabled = true

    if (this.hasReusableTarget) {
      this.reusableTarget.dataset.action = `${this.scope.identifier}#reuseCard`
    }
  }

  _handleAction(response) {
    const methodForModality = this._methodByModality()
    this.stripe[methodForModality](response.payment_intent_client_secret)
      .then((result) => {
        if (result.error) {
          ProcessingOverlay.hide()
          this.errorsTarget.hidden = false
          this.errorsTarget.innerText = result.error.message
        } else {
          fetch(this.prepareUrl, this._paramsForRequest({ payment_intent_id: result.paymentIntent.id }))
            .then((result) => {
              result.json().then((json) => this._handleServerResponse(json))
            }
          )
        }
      })
  }

  _handleServerResponse(response) {
    if (response.error) {
      this.errorsTarget.hidden = false
      this.errorsTarget.innerText = response.message
      this.submitTarget.disabled = false
      this.card.clear()
      ProcessingOverlay.hide()
    } else if (response.requires_action) {
      this._handleAction(response)
    } else if (response.success) {
      window.location.href = response.greeting
    }
  }

  _paramsForRequest(body) {
    return {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': this.token },
      body: JSON.stringify(body)
    }
  }

  _methodByModality() {
    return this.modality === 'immediate' ? 'handleCardAction' : 'confirmCardPayment'
  }

  get prepareUrl() {
    return this.data.get('prepare')
  }

  get token() {
    return document.querySelector('meta[name=csrf-token]').content
  }

  get overlayFeedback() {
    return this.data.get('feedback') || null
  }

  get modality() {
    const available = ['immediate', 'partial']
    const modality = this.data.get('modality')
    return available.includes(modality) ? modality : available[0]
  }
}
