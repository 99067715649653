class ProcessingOverlay {
  constructor() {
    this.overlay = null
  }

  show(message = null) {
    message = message ? message : 'A processar ...';

    if (!this.overlay) {
      // time to create a new overlay and attach it to the DOM
      let overlay = document.createElement('div')
      overlay.classList.add('overlay')
  
      // add spinner element
      let spinner = document.createElement('div')
      spinner.classList.add('overlay-spinner')
      overlay.appendChild(spinner)
  
      // add feedback message element
      let feedback = document.createElement('div')
      feedback.classList.add('overlay-message')
      feedback.innerText = message
      overlay.appendChild(feedback)
      
      document.body.appendChild(overlay)
      this.overlay = overlay
    } else {
      const feedback = this.overlay.querySelector('.overlay-message')
      feedback.innerText = message
      this.overlay.hidden = false
    }
  }

  hide() {
    if (this.overlay) this.overlay.hidden = true
  }

  destroy() {
    if (this.overlay) {
      this.overlay.remove()
      this.overlay = null
    }
  }
}

const processingOverlay = new ProcessingOverlay()
export default processingOverlay
