// app/javascript/controllers/event_dropdown_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dropdown", "link" ]

  connect() {
    this.updateLink()
  }

  updateLink() {
    const selectedEvent = this.dropdownTarget.value
    const newPath = `/pa/event_registration/export?event=${selectedEvent}`
    this.linkTarget.href = newPath
  }
}
