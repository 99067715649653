import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.checkAndScrollToLogo();
    document.addEventListener('turbo:load', () => this.checkAndScrollToLogo());
  }

  disconnect() {
    document.removeEventListener('turbo:load', () => this.checkAndScrollToLogo());
  }

  checkAndScrollToLogo() {
    const urlParams = new URLSearchParams(window.location.search);
    const shouldContinue = urlParams.get('continue');

    if (shouldContinue === 'true') {
      this.scrollToLogo()
    }
  }

  scrollToLogo() {
    const logoElement = document.getElementById('logo')
    if (logoElement) {
      logoElement.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
