import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'submit', 'clear' ]
  static values  = { path: String, message: String }

  change() {
    if (this.hasSubmitTarget) {
      this.displayCount();
    } else {
      this.element.requestSubmit();
    }
  }

  // if the focused element that triggered the search is one from the search bar
  // we will consider this a new search and will clear any selected filter
  clear() {
    if (this.clearTargets.includes(document.activeElement)) {
      this.element.querySelectorAll('input[name^=search]:checked').forEach((input) => {
        input.checked = false
      })
    }
  }

  async displayCount() {
    let response = await get(`${this.pathValue}?${new URLSearchParams(new FormData(this.element))}`, { contentType: 'application/json' });

    if (response.ok) {
      response.json.then((result) => {
        this.submitTarget.value = this.messageValue.replace(':count', result.count);
      })
    }
  }
}
