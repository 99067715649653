import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
    static targets = [ 'createArticle', 'input' ];
    static values = { path: String }

    connect() {    
        this.disabled = false
        this.spinner = this.createArticleTarget.parentElement.querySelector('.spinner');  
        this.createArticleTarget.addEventListener('click', (event) => {
            event.preventDefault();

            if (!this.disabled) {
                const title = document.querySelector('#article_title');
                const subtitle = document.querySelector('#article_subtitle');
                const meta_description = document.querySelector('#article_meta_description');

                if (title && title.value !== '' &&
                    subtitle && subtitle.value !== '' &&
                    meta_description && meta_description.value !== '' ) 
                {
                    this.generateArticle(title.value, subtitle.value, meta_description.value)
                }
                else {
                    alert('Campos de preenchimento obrigatório em falta.')
                }
            }
        })
    }

    async generateArticle(title, subtitle, meta_description) { 
        this.spinner.classList.remove('hide')
        this.disabled = true

        let params = { title: title, subtitle: subtitle, meta_description: meta_description };
        const response = await get(`${this.pathValue}?${new URLSearchParams(params)}`, { contentType: 'application/json' })
        
        if (response.ok) {
            response.json.then((result) => {
                this.inputTarget.innerText = result.content        
            })
        }

        this.spinner.classList.add('hide')
        this.disabled = false;
    }
}


