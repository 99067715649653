import { Controller } from "@hotwired/stimulus"
import CreditCard from "../../payments/credit_card"
import Multibanco from "../../payments/multibanco"
import DirectDebit from "../../payments/direct_debit"
import ProcessingOverlay from "../../managers/processing_overlay"

export default class extends Controller {
  static targets = [ "ccElement", "ibanElement", "options", "views", "errors", "submit", "renewal" ]

  initialize() {
    this.onPaymentValid = this._onPaymentValid.bind(this)
    this.onPaymentInvalid = this._onPaymentInvalid.bind(this)
    this.onPaymentError = this._onPaymentError.bind(this)
  }

  connect() {
    if (typeof Stripe !== 'undefined') {
      this._load()
    } else {
      setTimeout(this._load.bind(this), 1000)
    }
  }

  disconnect() {
    this.element.removeEventListener('payment:valid', this.onPaymentValid)
    this.element.removeEventListener('payment:invalid', this.onPaymentInvalid)
    this.element.removeEventListener('payment:error', this.onPaymentError)
  }

  onSubmit(event) {
    event.preventDefault()
    if (this.activeMethod.valid) {
      ProcessingOverlay.show(this.data.get('feedback') || null)
      this.activeMethod.process()
    }
  }

  show(event) {
    let button = event.currentTarget
    this._showOption(button)
  }

  _showOption(button) {
    this._selectOption(button)
    this._showView(button.dataset.tab)
  }

  _selectOption(button) {
    this._deselectOptions()

    let match = Array.from(this.optionsTarget.childNodes).find((reference) => reference === button)
    if (match) match.setAttribute('aria-pressed', true)
  }

  _deselectOptions() {
    this.optionsTarget.childNodes.forEach((view) => view.setAttribute('aria-pressed', false))
  }

  _hiddenViews() {
    this.viewsTarget.childNodes.forEach((view) => view.hidden = true)
  }

  _showView(id) {
    this._hiddenViews()

    let view = this.viewsTarget.querySelector(id)
    view.hidden = false

    if (!this.paymentMethods.hasOwnProperty(id)) {
      this.paymentMethods[id] = this._classForPayment(view)
    }

    this.activeMethod = this.paymentMethods[id]
    // check if the current active method is valid or not
    this.activeMethod.valid ? this._onPaymentValid() : this._onPaymentInvalid()
    // we need to check if is an automatic renewal or not and act accordingly in the
    // layout
    this._infoForPayment()
  }

  _classForPayment(element) {
    let source = null

    switch (element.id) {
      case 'creditCard':
        source = new CreditCard(this.ccElementTarget, this.element)
      break;
      case 'multibanco':
        source = new Multibanco(element, this.element)
      break;
      case 'directDebit':
        source = new DirectDebit(this.ibanElementTarget, this.element)
      break;
    }

    return source
  }

  _load() {
    this.activeMethod = null
    this.paymentMethods = {}
    this.element.addEventListener('payment:valid', this.onPaymentValid)
    this.element.addEventListener('payment:invalid', this.onPaymentInvalid)
    this.element.addEventListener('payment:error', this.onPaymentError)
    this.element.addEventListener('submit', (event) => {
      event.stopPropagation();
      return false;
    })
    this.submitTarget.disabled = true

    if (this.hasOptionsTarget) {
      let selected = this.optionsTarget.querySelector('.button:not(disabled)')
      if (selected) this._showOption(selected)
    } else {
      this._showView(`#${this.viewsTarget.firstChild.id}`)
    }
  }

  _onPaymentError(event) {
    if (event) this.errorsTarget.innerText = event.detail
    this.submitTarget.disabled = false
    this.errorsTarget.hidden = false
    ProcessingOverlay.hide()
  }

  _onPaymentValid() {
    this.submitTarget.disabled = false
    this.errorsTarget.hidden = true
  }

  _onPaymentInvalid(event) {
    if (event) this.errorsTarget.innerText = event.detail
    this.submitTarget.disabled = true
    this.errorsTarget.hidden = false
    ProcessingOverlay.hide()
  }

  _infoForPayment() {
    let renewalElement = this.renewalTarget
    let label = this.activeMethod.automatic ? renewalElement.dataset.labelYes : renewalElement.dataset.labelNo
    renewalElement.innerText = label
  }
}
