import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

export default class extends Controller {
  static targets = [
    "rangeFilter",
    "dateTimePicker",
    "submitDate",
    "toDate",
    "fromDate",
    "fromDateTime",
    "datePickerFeaturedListing"
  ]

  static get shouldLoad() {
    return window.railsController != 'home';
  }

  connect() {
    // Create a map of options based on target type
    const optionsMap = {
      rangeFilter: {
        locale: Portuguese,
        mode: "range",
        minDate: "1900-01-01", // Set minDate to 2000-01-01
        maxDate: "today", // Set maxDate to today to prevent future dates
        onClose: (selectedDates, dateStr, instance) => {
          if(selectedDates.length === 2) {
            instance.element.dispatchEvent(new Event('rangeChange'))
          }
        }
      },
      dateTimePicker: {
        locale: Portuguese,
        enableTime: true,
        noCalendar: false,
        time_24hr: true,
        allowInput: true,
        minDate: "1900-01-01", // Set minDate to 2000-01-01
        maxDate: "today" // Set maxDate to today to prevent future dates
      },
      submitDate: {
        locale: Portuguese,
        allowInput: true,
        onChange: () => this.element.requestSubmit(),
        minDate: "1900-01-01", // Set minDate to 2000-01-01
        maxDate: "today" // Set maxDate to today to prevent future dates
      },
      fromDate: {
        locale: Portuguese,
        allowInput: true,
        minDate: "1900-01-01", // Set minDate to 2000-01-01
        maxDate: "today" // Set maxDate to today to prevent future dates
      },
      toDate: {
        locale: Portuguese,
        allowInput: true,
        minDate: "1900-01-01", // Set minDate to 2000-01-01
      },
      fromDateTime: {
        locale: Portuguese,
        allowInput: true,
        minDate: "1900-01-01", // Set minDate to 2000-01-01
        maxDate: "today", // Set maxDate to today to prevent future dates
        enableTime: true
      },
      datePickerFeaturedListing: {
        locale: Portuguese,
        minDate: "2000-01-01", // Set minDate to 2000-01-01
        defaultDate: 'today'
      }
    };

    // Iterate through each target type
    for (const targetType in optionsMap) {
      this[`${targetType}Targets`].forEach((picker) => {
        // Get specific options for this target type
        const options = { ...optionsMap[targetType] };

        // Add minDate, maxDate, start_date, end_date if they exist
        const minDate = picker.getAttribute('data-min-date');
        const maxDate = picker.getAttribute('data-max-date');
        const startDate = picker.getAttribute('data-start-date');
        const endDate = picker.getAttribute('data-end-date');

        if (minDate) options.minDate = minDate;
        if (maxDate) options.maxDate = maxDate;
        if (startDate && endDate) options.defaultDate = [startDate, endDate];

        // Initialize flatpickr with the options
        flatpickr(picker, options);

        // Add event listener for rangeChange if it's a rangeFilter
        if (targetType === 'rangeFilter') {
          picker.addEventListener('rangeChange', () => this.element.requestSubmit());
        }
      });
    }
  }
}
