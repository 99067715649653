import { Controller } from "@hotwired/stimulus"
import { debounce } from '../utils';

export default class extends Controller {
  static targets = [ 'logo', 'form' ]

  connect() {
    this.store = debounce(() => { this.formTarget.requestSubmit() }, 200);
  }

  setColor(event) {
    this.logoTarget.style.backgroundColor = event.target.value;
    this.store();
  }
}
