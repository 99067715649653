import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    setTimeout(() => {
      console.log('Closing modal after 10 second');
      this.close();
    }, 3000);
    this.modalTarget.addEventListener('click', () => this.close());

  }

  open() {
    this.modalTarget.style.display = "block";
  }

  close() {
    console.log("close")
    this.modalTarget.style.display = "none";
  }
}
