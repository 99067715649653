import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "debit", "credit", "day", "submit" ]
  static values = { hourlyPrice: Number, referenceDate: Number, today: Number }

  connect() {
    this.onChange()
  }

  onChange() {
    // ok, we need to retrieve the selected day and we will convert to the desired date, which
    // we need to compare against current date if is below we need to move it to next month
    const selectedDate = new Date(this.referenceDate.getTime())
    selectedDate.setDate(parseInt(this.dayTarget.value))

    if (this.today > selectedDate) selectedDate.setMonth(selectedDate.getMonth() + 1)

    if (selectedDate < this.referenceDate) {
      this._handleCredit(selectedDate)
    } else if (selectedDate > this.referenceDate) {
      this._handleDebit(selectedDate)
    } else {
      this.debitTarget.setAttribute('hidden', true)
      this.creditTarget.setAttribute('hidden', true)
    }

    this.submitTarget.toggleAttribute('disabled', selectedDay === referenceDay)
  }

  _handleDebit(date) {
    this.creditTarget.setAttribute('hidden', true)
    this._displayAmount(this.debitTarget, date)
  }

  _handleCredit(date) {
    this.debitTarget.setAttribute('hidden', true)
    this._displayAmount(this.creditTarget, date)
  }

  _displayAmount(element, date) {
    const referenceDate = this.referenceDate

    // and now we will compute the difference in hours that we will
    // use to compute the value
    const totalHours = Math.abs(date - referenceDate) / 36e5

    // get price placeholder
    const priceElement = element.querySelector('.price__amount')
    if (priceElement) {
      priceElement.innerText = (totalHours * this.hourlyPriceValue).toFixed(2).replace('.', ',')
    }

    element.removeAttribute('hidden')
  }

  get today() {
    return new Date(this.todayValue * 1000)
  }

  get referenceDate() {
    return new Date(this.referenceDateValue * 1000)
  }
}
