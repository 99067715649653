import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [ 'image' ]
  static values = { path: String }

  connect() {
    if (navigator.geolocation && this.hasImageTarget)  {
      navigator.geolocation.getCurrentPosition(position => this.replaceImage(position))
    };
  }

  async replaceImage(position) {
    let response = await get(`${this.pathValue}?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}`, { contentType: 'application/json' });

    if (response.ok) {
      response.json.then((result) => {
        if (result.image) {
          this.imageTarget.src = result.image;
          this.imageTarget.classList.add('opaque');
        }
      })
    }
  }
}
