import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'hero', 'tab' ];

  showAll() {
    this.heroTarget.classList.add('hide');
    this.tabTargets.forEach(tab => tab.classList.add('is-active'))
  }

  close() {
    this.heroTarget.classList.remove('hide');
    this.tabTargets.forEach(tab => tab.classList.remove('is-active'));
  }

  disconnect() {
    this.close();
  }
}
