// Purpose: Open all button controller to open the gallery modal.
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  connect() {
  }

  openModal(event) {
    event.preventDefault()
    const galleryModal = document.querySelector('[data-controller="gallery-modal"]')
    if (galleryModal) {
      const openEvent = new CustomEvent('gallery-modal:open', { bubbles: true })
      galleryModal.dispatchEvent(openEvent)
    }
  }
}
