import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "form" ]

  search() {
    event.preventDefault();
    event.stopPropagation();

    this.inputTarget.value = this.inputTarget.value == 'true' ? 'false' : 'true';
    this.formTarget.requestSubmit();
  }
}
