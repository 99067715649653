import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "start", "end", "type", "category", "submit", "options", "verticals", "categories", "district", "region", "featuresLocations", "featuresLocationsOptions", "image", "imageOptions", "alternateImage", "color", "colorOptions" ]
  
  initialize() {
    this.limit = 1
    this.source = null
  }

  connect() {
   this._applyListeners()
   this._typeCheck()
  }

  onTypeChange() {
    this._typeCheck()
  }

  onCategoryChange() {
    this._categoryCheck()
  }

  onDateChange() {
    const temp = this.startTarget.value
    const startDate = new Date(temp)
    const endDate = new Date(this.endTarget.value)
    if (startDate > endDate) {
      this.startTarget.value = this.endTarget.value
      this.endTarget.value = temp
    }
  }

  _applyListeners() {
    this.typeTargets.forEach((option) => {
      option.querySelector('input[type="radio"]').dataset.action = `${this.scope.identifier}#onTypeChange`
    })
  }

  _typeCheck() {
    const selected = this.selectedType
    if (selected) {
      this.limit = selected.dataset.limit
      const objectSource = selected.dataset.objectSource
      const colorRequired = objectSource == 'banner'
      const imageRequired = objectSource == 'banner' || objectSource == 'searchbox'
      const alternateImageRequired = objectSource == 'banner'
      const featuresLocationsRequired = objectSource == 'homepage' || objectSource == 'vertical'
      const optionsToRender = objectSource == 'homepage' ? 'verticals' : 'categories'
      const imageNote = objectSource == 'banner' ? '(Largura: 1000 px, Altura: 280 px)' : ''
      const alternateImageNote = '(Largura: 175 px, Altura: 500 px)'

      this._renderOptions(optionsToRender)
      this._renderFeaturesLocations(featuresLocationsRequired)
      this._renderImage(imageRequired, alternateImageRequired, imageNote, alternateImageNote)
      this._renderColor(colorRequired)
      this._categoryCheck()

    } else {
      this.submitTarget.setAttribute('disabled', 'disabled')
    }
  }

  _categoryCheck() {
    const selected = this.selectedCategories
    if (selected.length >= this.limit) {
      // disable all the others
      this.categoryTargets.forEach((option) => {
        if (!selected.includes(option)) option.classList.add('disabled')
      })
    } else {
      this.categoryTargets.forEach((option) => option.classList.remove('disabled'))
    }

    if (selected.length != this.limit) {
      this.submitTarget.setAttribute('disabled', 'disabled')
    } else {
      this.submitTarget.removeAttribute('disabled')
    }
  }

  _renderOptions(source) {
    if (this.source !== source) {
      this.source = source
      const template = this[`${this.source}Target`].innerHTML
      this.optionsTarget.innerHTML = template
    }
  }

  _renderImage(imageRequired, alternateRequired, imageNote, alternateNote) {
    if (this.hasImageTarget) {
      const imageElement = this.imageTarget.content.querySelector('#image-image');
      
      if (imageRequired && imageElement) {
        imageElement.required = true;
      }else{
        imageElement.required = false;
      }
      
      // Set requirements text for image
      const requirementElement = this.imageTarget.content.querySelector('#requirement');
      requirementElement.innerHTML = imageNote;

      // Show image option
      this.imageOptionsTarget.innerHTML = this.imageTarget.innerHTML;  
      
      if (alternateRequired && this.alternateImageTarget) {
        const alternateImageElement = this.alternateImageTarget.content.querySelector('#alternate-image');        
        alternateImageElement.required = false;
      
        // Set requirements text for alternate image
        const requirementElement = this.alternateImageTarget.content.querySelector('#requirement');
        requirementElement.innerHTML = alternateNote;
        
        // Show alternate image option
        this.imageOptionsTarget.innerHTML += this.alternateImageTarget.innerHTML; 
      }
    }
  }

  _renderColor(required) {
    if (required) {
      const template = this.colorTarget.innerHTML
      this.colorOptionsTarget.innerHTML = template
    } else {
      this.colorOptionsTarget.innerHTML = ""
    }
  }

  _renderFeaturesLocations(required) {
    if (required) {
      const template = this.featuresLocationsTarget.innerHTML
      this.featuresLocationsOptionsTarget.innerHTML = template
    }
    else {
      this.featuresLocationsOptionsTarget.innerHTML = ""
    }
  }

  get selectedType() {
    return this.typeTargets.find((option) => option.querySelector('input[type="radio"]').checked)
  }

  get selectedCategories() {
    return this.categoryTargets.filter((option) => option.querySelector('input[type="checkbox"]').checked)
  }

  checkRegion() {
    this.districtTargets.forEach((district) => { district.checked = false})
  }

  checkDistrict() {
    this.regionTargets.forEach((region) => { region.checked = false})
  }
}
