import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "selectAll", "input", "submit" ]

  connect() {
    this.#checkSubmitStatus()
  }

  onSelectAll(event) {
    const checkbox = event.currentTarget
    if (checkbox.checked) {
      this.inputTargets.forEach((input) => input.checked = true)
    } else {
      this.inputTargets.forEach((input) => input.checked = false)
    }

    this.#checkSubmitStatus()
  }

  onSelect() {
    this.#checkSubmitStatus()
    this.selectAllTarget.checked = this.totalInputs === this.checkedInputs
  }

  #checkSubmitStatus() {
    if (!this.hasSubmitTarget) return

    const selected = this.inputTargets.filter((input) => input.checked)
    this.submitTarget.toggleAttribute("disabled", selected.length === 0)
  }

  get totalInputs() {
    return this.inputTargets.length
  }

  get checkedInputs() {
    return this.inputTargets.filter((input) => input.checked).length
  }
}