import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
import * as Utils from 'utils.js';

export default class extends Controller {
  static targets = ['container', 'input', 'clear', 'location', 'locationId', 'region', 'municipality', 'parish', 'country', 'back', 'bbne', 'bbsw'];
  static values = { path: String}

//   static get shouldLoad() {
//     return window.railsEnvironment != 'test';
//   }

  connect() {
    const debounceMs = 300;

    this.inputTarget.addEventListener('input', this.toggleClearButton.bind(this));

    this.clearTarget.addEventListener('click', () => {
      this.clearLocationInput()
    })

    this.inputTarget.addEventListener('click', () => {
      this.getLocations()
    })

    this.inputTarget.addEventListener('keyup',  Utils.debounce((ev) => {
      let inputValue = this.inputTarget.value.trim();
      
      // Clear location name
      this.locationTarget.value = '';
      this.locationTarget.dispatchEvent(new Event('change'));

      // Get locations for input
      this.getLocations(inputValue)

    }, debounceMs));

    document.addEventListener("click", (event) => {
      if (!event.target.closest("." + this.containerTarget.className) && event.target.id != this.inputTarget.id) {
        this.containerTarget.classList.add('hide')
      };
    });
  }

  select(element) {
    const bbsw=element.dataset.bbsw;
    const bbne=element.dataset.bbne;
    const name=element.dataset.name;
    const id=element.dataset.id;

    this.locationIdTarget.value = id;

    this.locationTarget.value = name;
    this.inputTarget.value = name;
    
    this.bbswTarget.value=bbsw;
    this.bbneTarget.value=bbne;

    this.containerTarget.classList.add('hide')
    this.locationTarget.dispatchEvent(new Event('change'));
    this.toggleClearButton();
  }

  toggleClearButton() {
    // If there is input in the search bar, add a class to hide the clear target
    if (this.inputTarget.value) {
      this.clearTarget.classList.remove('d-none')
      this.clearTarget.classList.add('d-flex')
    } else {
      // If there is no input in the search bar, remove the class to show the clear target
      this.clearTarget.classList.remove('d-flex')
      this.clearTarget.classList.add('d-none')
    }
  }

  clearLocationInput() {
    this.inputTarget.value = '';
    this.locationTarget.value = '';
    this.locationIdTarget.value = '';
    this.locationTarget.dispatchEvent(new Event('change'));
    this.containerTarget.classList.add('hide')
    this.toggleClearButton()
  }

  async getLocations(query, regionId, municipalityId) {
    let params = { };

    if (query && query.length > 1) {
      params = Object.assign(params, { query: query });
    }
    else if (regionId) {
      params = Object.assign(params, { region: regionId });
    }
    else if (municipalityId) {
      params = Object.assign(params, { municipality: municipalityId });
    }

    const url =  `${this.pathValue}?${new URLSearchParams(params)}`;
    const response = await get(url);

    if (response.ok) {
      const result = await response.html; 

      if (!result) {
        return
      }

      this.containerTarget.innerHTML = result;
      this.containerTarget.classList.remove('hide')

      this.backTargets.forEach(item => {
        item.addEventListener('click', (event) => {
          this.getLocations()
        });
      });

      // Country target: only portugal
      this.countryTargets.forEach(item => {
        item.addEventListener('click', (event) => {
          if (item.hasAttribute('data-linkable')) {
            this.select(item)
          }
        });
      });

      // Region targets
      this.regionTargets.forEach(item => {
        item.addEventListener('click', (event) => {
        
          if (item.hasAttribute('data-linkable')) {
            this.select(item)
          
          } else {
            const regionId = item.dataset.id
          
            if (regionId) {
              this.getLocations(undefined, regionId, undefined)
            }
          }
        });
      });

      // Municipalities targets
      this.municipalityTargets.forEach(item => {
        item.addEventListener('click', (event) => {
          if (item.hasAttribute('data-linkable')) {
            this.select(item)
          } else {
            const municipalityId = item.dataset.id
          
            if (municipalityId) {
              this.getLocations(undefined, undefined, municipalityId)
            }
          }
        });
      });

      // Parish targets
      this.parishTargets.forEach(item => {
        item.addEventListener('click', (event) => {
          if (item.hasAttribute('data-linkable')) {
            this.select(item)
          }
        });
      });

    }
  }
}
