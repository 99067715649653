import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "closed", "entries", "openAllDay", "options" ]

  connect() {
    this.onVisibilityChange()
    this.onOpenAllDayChange()
  }

  onVisibilityChange() {
    this.entriesTarget.hidden = this.closedTarget.checked
  }

  onOpenAllDayChange() {
    this.optionsTarget.hidden = this.openAllDayTarget.checked
  }
}
