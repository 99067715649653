import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  open(event) {
    event.preventDefault();
    const position = this._popupPosition(351, 550);
    let target = event.currentTarget;
    window.open(
      target.href,
      target.innerText, 
      `menubar=no,toolbar=no,width=351,height=550,resizable=yes,scrollbars=no,directories=no,location=no,top=${position.top},left=${position.left}`
    );
  }

  _popupPosition(w, h) {
    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    return { left: left, top: top };
  }
}
