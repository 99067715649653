// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails"
import "../controllers"
import "@rails/actiontext"

import 'promise-polyfill/src/polyfill';
import "polyfills";
import "cookie-consent.js";

import { Foundation } from 'foundation-sites/js/foundation.core';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { Toggler } from 'foundation-sites/js/foundation.toggler';
import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu';
import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
import { Tooltip } from 'foundation-sites/js/foundation.tooltip';
import { Tabs } from 'foundation-sites/js/foundation.tabs';
import { Triggers } from 'foundation-sites/js/foundation.util.triggers';
import { Touch } from 'foundation-sites/js/foundation.util.touch';

window.initAutocompleteDummy = function() {
  // Implemented to disable google warning
  // JS API is loaded and available
};

Foundation.addToJquery($);

Touch.init($);
Triggers.init($, Foundation);
MediaQuery._init();

Foundation.plugin(DropdownMenu, 'DropdownMenu');
Foundation.plugin(Reveal, 'Reveal');
Foundation.plugin(Tabs, 'Tabs');
Foundation.plugin(Toggler, 'Toggler');
Foundation.plugin(Tooltip, 'Tooltip');
Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');

document.addEventListener("turbo:load", () => {
  $(document).foundation();

  if (window.dataLayer) {
    window.dataLayer.push({'event':'pageview', 'virtualPath': window.location.pathname});
  }
});

document.addEventListener("turbo:before-cache", function() {
  const tooltips = $('.has-tip');
  if (tooltips.length > 0) {
    tooltips.foundation('destroy');
    tooltips.attr('data-tooltip', 'yes');
  };

});

// Show flash when a Turbo Frame is rendered
// TODO: Turbo/Rails will probably have an idiomatic solution in the future
document.addEventListener("turbo:frame-render", () => {
  const flashContainer = event.currentTarget.querySelector('.flash-container');
  event.detail.fetchResponse.responseHTML.then((html) => {
    const newflashContainer = (new DOMParser()).parseFromString(html, "text/html").querySelector('.flash-container');
    if (newflashContainer) {
      const flashContent = newflashContainer.innerHTML;
      if (flashContent.length > 0) flashContainer.innerHTML = flashContent;
    }
  })
})
