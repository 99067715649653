import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'clear', 'input' ];

  connect() {
    this.element.querySelectorAll('select, input[type="checkbox"], input[type="radio"]').forEach((control) => {
      control.addEventListener('change', () => this.element.requestSubmit())
    })

    if (this.hasInputTarget && this.hasClearTarget) {
      this._refreshClearButtonVisibility()

      this.inputTarget.addEventListener("input",  () => {
        this._refreshClearButtonVisibility()
      })
    }
  }

  clear() {
    this.inputTarget.value = "";
    this._refreshClearButtonVisibility();
    this.element.requestSubmit();
  }

  _refreshClearButtonVisibility() {

    if (this.inputTarget.value.trim() !== "") {
      this.clearTarget.classList.remove('hidden');
    } else {
      this.clearTarget.classList.add('hidden');
    }
  }
}
