import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.onChange = this._setFieldState.bind(this)
    this._handleError()
    this._swapElements()
    this.element.classList.add('is-floating')
    if (this.field) {
      this.field.addEventListener('change', this.onChange)
      this._setFieldState()
    }
  }

  disconnect() {
    this._swapElements()
    this.element.classList.remove('is-floating')
    if (this.field) this.field.removeEventListener('change', this.onChange)
  }

  _swapElements() {
    let childNodes = this.element.childNodes
    this.element.insertBefore(childNodes[1], childNodes[0])
  }

  _handleError() {
    let error = this.element.querySelector('.field-with-errors')
    if (error) {
      this.element.classList.add('has-errors')
      this.element.replaceChild(error.childNodes[0], error)
    }
  }

  _setFieldState() {
    let input = this.field
    if (input.nodeName === 'SELECT') {
      input.classList.add('has-content')
    } else if (input.value !== '') {
      input.classList.add('has-content')
    } else {
      input.classList.remove('has-content')
    }
  }

  get field() {
    return Array.from(this.element.childNodes).find((node) => ['INPUT', 'SELECT', 'TEXTAREA'].includes(node.nodeName))
  }
}
