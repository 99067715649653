import { put } from '@rails/request.js'

class Multibanco {
  constructor(element, form) {
    this.sourceID = null
    this.element = element
    this.form = form
    this.stripe = Stripe(window.stripeKey, { locale: 'pt' })
    this.isValid = true
  }

  async process() {
    const response = await put(`${this.form.action}/multibanco`, { contentType: 'application/json' });
    if (response.ok) {
      response.json.then((result) => Turbo.visit(result.location));
    }
  }

  get valid() {
    return this.isValid
  }

  get automatic() {
    return false
  }
}

export default Multibanco
