import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "plan", "options", "cost", "template", "fee" ]

  connect() {
    this.options = []
    this._displayOptions()
  }

  disconnect() {
    this.options = []
    this.optionsTarget.innerHTML = ''
  }

  doChange() {
    this._displayOptions()
  }

  onPricingChange() {
    let checked = this.optionsTarget.querySelector('input:checked')
    let pricing = this.options.find((option) => option.pricing === checked.value)
    if (pricing) {
      const priceWithFee = +pricing.price_without_vat + this.planSetupFee;
      this.costTarget.innerText = this._formattedAmount(priceWithFee, pricing.currency)
      this._updateSetupFee()
    }
  }

  _formattedAmount(price, currency) {
    return new Intl.NumberFormat(navigator.language, 
      { style: 'currency', currency: currency.toUpperCase() }
    ).format(price)
  }

  _displayOptions() {
    this.optionsTarget.innerHTML = ''
    let options = this._optionsForSelected()
    let template = this.templateTarget
    options.forEach((option) => {
      let element = document.createRange().createContextualFragment(template.innerHTML)
      let identifier = `pricing_${option.pricing.toLowerCase()}`
      // we will set the input with the right values, both for value as to id
      let input = element.querySelector('input')
      input.value = option.pricing
      input.id = identifier
      input.dataset.action = `${this.scope.identifier}#onPricingChange`
      // now we will handle label
      let label = element.querySelector('label')
      label.setAttribute('for', identifier)
      label.querySelector('[data-item="label"]').innerText = option.name
      label.querySelector('[data-item="description"]').innerText = this._formattedAmount(option.price_without_vat, option.currency) 
      this.optionsTarget.appendChild(element)
    })
    // finally we will select the first one and display it's price
    this.optionsTarget.querySelector('input').click()
    this.onPricingChange()

    // now update the fee for the selected plan
    this._updateSetupFee()
  }

  get selectedPlan() {
    return this.planTargets.find((element) => element.checked)
  }

  get planSetupFee() {
    return this.selectedPlan.dataset.fee ? +this.selectedPlan.dataset.fee / 1.23 : 0
  }

  _optionsForSelected() {
    const selectedPlan = this.selectedPlan
    if (!selectedPlan) this.planTargets[0].checked = true

    const parsedOptions = JSON.parse(this.selectedPlan.dataset.options)
    this.options = parsedOptions.sort((reference, compare) => reference.interval_count > compare.interval_count)
    return this.options
  }

  _updateSetupFee() {
    this.feeTarget.value = this.planSetupFee * 1.23
  }
}
