import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["line", "total", "fee", "feeInput", "feeAmount"]

  initialize() {
    this.onAddonUpdate = this._onAddonUpdate.bind(this)
  }

  connect() {
    this.element.addEventListener('calculator.update', this.onAddonUpdate)
    if (this.hasFeeInputTarget) {
      this._setSetupFee()
    } else {
      this._updateTotal()
    }
  }

  disconnect() {
    this.element.removeEventListener('calculator.update', this.onAddonUpdate)
  }

  onFeeToggle() {
    this._setSetupFee()
  }

  _setSetupFee() {
    const feePreview = this.element.querySelector('#setupFeePreview')
    if (!feePreview) return

    const input = this.feeInputTarget
    const price = parseFloat(input.dataset.feeAmount)
    const priceWithoutVAT = price / 1.23

    feePreview.innerHTML = this._formattedPrice(input.checked ? priceWithoutVAT : 0)
    this.feeAmountTarget.value = input.checked ? price : 0
    this._updateTotal()
  }

  _updateTotal() {
    const total = this.linesTotal
    const priceElement = this.totalTarget.querySelector('[data-price]')
    priceElement.dataset.price = total

    const priceAmountElement = priceElement.querySelector('.price__amount')
    priceAmountElement.innerText = total.toFixed(2).replace('.', ',')
  }

  _onAddonUpdate() {
    this._updateTotal()
  }

  _formattedPrice(price) {
    return `
      <span class="price" data-price="${price}">
        <span class="price__amount">${price.toFixed(2).replace('.', ',')}</span><span class="price__currency">€</span>
      </span>
    `
  }

  get linesTotal() {
    return this.lineTargets.map((line) => {
      const priceElement = line.querySelector('[data-price]')
      return parseFloat(priceElement.dataset.price)
    }).reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    }, 0)
  }
}
