import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.classList.remove('is-collapsed')
  }

  toggle() {
    this.element.classList.toggle('is-collapsed')
  }
}
