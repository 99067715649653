import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "clickable", "submit", "stats" ]

  connect() {
    this._choicesCheck()
    this._applyListener()
  }

  onChange() {
    this._choicesCheck()
  }

  _choicesCheck() {
    const selected = this.selectedChoices
    if (selected.length >= this.choicesLimit) {
      // disable all the others
      this.clickableTargets.forEach((option) => {
        if (!selected.includes(option)) option.classList.add('disabled')
      })
    } else {
      this.clickableTargets.forEach((option) => option.classList.remove('disabled'))
    }

    if (this.hasSubmitTarget) {
      if (selected.length != this.choicesLimit) {
        this.submitTarget.setAttribute('disabled', 'disabled')
      } else {
        this.submitTarget.removeAttribute('disabled')
      }
    }

    if (this.hasStatsTarget) this._showStats()
  }

  _applyListener() {
    this.clickableTargets.forEach((option) => {
      option.querySelector('input[type="checkbox"]').dataset.action = `${this.scope.identifier}#onChange`
    })
  }

  _showStats() {
    const label = this.statsTarget.dataset.label
    if (!label) return
    this.statsTarget.innerText = label.replace('#SELECTED#', this.selectedChoices.length).replace('#TOTAL#', this.choicesLimit)
  }

  get choicesLimit() {
    return this.data.get('limit') || 1
  }

  get selectedChoices() {
    return this.clickableTargets.filter((option) => option.querySelector('input[type="checkbox"]').checked)
  }
}
