import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["bookCode", "itemCode"]

  connect() {
    this.updateItemCodeSelection();  
  }

  updateItemCodeSelection() {
    const bookCode = this.bookCodeTarget.value; 
    const itemCodeOptions = JSON.parse(this.bookCodeTarget.dataset.itemCodeOptions); 

    let options;
    if (bookCode === '') {  
      options = Object.values(itemCodeOptions).flat();
      options = Array.from(new Set(options));
    } else {
      options = itemCodeOptions[bookCode] || [];
    }

    this.updateDropdown(options);
    this.restoreSelectedItemCode();
  }

  updateDropdown(options) {
    this.itemCodeTarget.innerHTML = '';

    const allOption = document.createElement('option');
    allOption.value = '';
    allOption.text = 'Todos';
    this.itemCodeTarget.appendChild(allOption);

    options.forEach(option => {
      const opt = document.createElement('option');
      opt.value = option;
      opt.text = option;
      this.itemCodeTarget.appendChild(opt);
    });
  }

  updateItensCodesCodeSelection() {
    const selectedItemCode = this.itemCodeTarget.value;
    localStorage.setItem('selectedItemCode', selectedItemCode);
  }

  restoreSelectedItemCode() {
    const selectedItemCode = localStorage.getItem('selectedItemCode') || ''; 
    this.itemCodeTarget.value = selectedItemCode;
  }
}
