import { Controller } from "@hotwired/stimulus"
import Elements from "../../payments/elements"

export default class extends Controller {
  static targets = [ "iban", "holder", "email", "feedback", "tokenizer", "submit" ]
  static values = { secret: String }

  static get shouldLoad() {
    return window.railsEnvironment != 'test';
  }

  connect() {
    this.stripe = Stripe(window.stripeKey, { locale: 'pt' })
    this.elements = this.stripe.elements()
    this.iban = this.elements.create('iban', { style: Elements.styles(),
                                               supportedCountries: ['SEPA'],
                                               placeholderCountry: 'PT' })
    this.iban.mount(this.ibanTarget)
    this.iban.addEventListener('change', this.onChange.bind(this))
    this.submitTarget.disabled = true
    this.isValid = false
  }

  onChange(iban) {
    if (iban.complete) {
      this.feedbackTarget.innerText = ''
      this.submitTarget.disabled = false
      this.isValid = true
    } else if (iban.error) {
      this.feedbackTarget.innerText = iban.error.message
      this.submitTarget.disabled = true
      this.isValid = false
    }
  }

  onSubmit(event) {
    event.preventDefault()

    if (this.isValid) {
      this.stripe.confirmSepaDebitSetup(
        this.secretValue,
        {
          payment_method: {
            sepa_debit: this.iban,
            billing_details: { name: this.holderTarget.value,
                               email: this.emailTarget.value }
          }
        }
      ).then((result) => {
        if (result.error) {
          this.feedbackTarget.innerText = result.error.message
        } else {
          this.tokenizerTarget.value = result.setupIntent.id
          this.element.submit()
        }
      })
    }
  }
}
