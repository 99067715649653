import { put } from '@rails/request.js'
import Elements from "./elements"

class CreditCard {
  constructor(element, form) {
    this.stripe = Stripe(window.stripeKey, { locale: 'pt' })
    this.elements = this.stripe.elements()
    this.card = this.elements.create('card', { style: Elements.styles() })
    this.card.mount(element)
    this.card.addEventListener('change', this.onChange.bind(this))
    this.card.addEventListener('focus', this.onFocus.bind(this))
    this.form = form
    this.isValid = false
    this.isCardValid = false
    this.bindExistingCards()
  }

  bindExistingCards() {
    const existingCards = this.form.querySelectorAll('.existing-card')
    existingCards.forEach((card) => {
      card.addEventListener('change', () => {
        this.isValid = true
        this.form.dispatchEvent(new CustomEvent('payment:valid'))
      })
    })
  }

  onChange(card) {
    if (card.complete) {
      this.form.dispatchEvent(new CustomEvent('payment:valid'))
      this.isValid = true
      this.isCardValid = true
    } else if (card.error) {
      this._triggerError(card.error.message)
    } else {
      this._triggerError()
    }
  }

  onFocus() {
    const checkedCard = this.form.querySelector('.existing-card:checked')
    if (checkedCard) checkedCard.checked = false

    if (!this.isCardValid) this.form.dispatchEvent(new CustomEvent('payment:invalid'))
  }

  process() {
    const existingCard = this.form.querySelector('.existing-card:checked')
    if (existingCard) {
      this._assignSubscription(existingCard.value)
    } else {
      this.stripe
        .createPaymentMethod('card', this.card)
        .then((result) => {
          if (result.error) {
            this._triggerError(result.error.message)
          } else {
            this._assignSubscription(result.paymentMethod.id)
          }
        })
    }
  }

  async _assignSubscription(nonce) {
    // build params for the subscription creation request, we always will receive the nonce
    let params = { nonce: nonce }

    // however for billing day we will just check if we have the element first
    const billingDayElement = this.form.querySelector('[name="cc_billing_day"]')
    if (billingDayElement) params.billing_day = billingDayElement.value

    const response = await put(this.form.action, { contentType: 'application/json', body: params });
    if (response.ok) {
      response.json.then((result) => {
        if (result.success) {
          this._handleSubscription(result.subscription);
        } else {
          this.form.dispatchEvent(new CustomEvent('payment:error', { bubbles: true, detail: result.message }))
        }
      });
    }
  }

  _handleSubscription(subscription) {
    const { latest_invoice } = subscription
    const { payment_intent } = latest_invoice

    if (payment_intent) {
      const { client_secret, status } = payment_intent

      if (status === 'requires_action' || status === 'requires_payment_method') {
        this.stripe.confirmCardPayment(client_secret).then((result) => {
          if (result.error) {
            this._triggerError(result.error.message)
          } else {
            this._complete()
          }
        })
      } else {
        this._complete()
      }
    } else {
      this._complete()
    }
  }

  async _complete() {
    const response = await put(`${this.form.action}/complete`, { contentType: 'application/json'});
    if (response.ok) {
      response.json.then((result) => Turbo.visit(result.location));
    }
  }

  _triggerError(message = null) {
    this.form.dispatchEvent(new CustomEvent('payment:invalid', { detail: message }))
    this.isValid = false
    this.isCardValid = false
  }

  get valid() {
    return this.isValid
  }

  get automatic() {
    return true
  }
}

export default CreditCard
