import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get shouldLoad() {
    return false && window.railsEnvironment != 'test';
  }

  connect() {
    if (document.documentElement.hasAttribute("data-turbo-preview")) return;

    var tags       = JSON.parse(this.data.get("tags"));
    var categories = JSON.parse(this.data.get("categories"));
    var verticals  = JSON.parse(this.data.get("verticals"));

    window.googletag.cmd.push(function() {
      window.googletag.pubads().clear();
    });

    if (window.googletag.pages.article) {
      window.googletag.cmd.push(function() {
        window.googletag.pubads()
           .setTargeting('vertical', verticals)
           .setTargeting('tags-vertical', tags)
           .setTargeting('categoria', categories)
           .setTargeting('location', 'my_location');

        window.googletag.pubads().refresh([
          window.googletag.slots['article_slot1'],
          window.googletag.slots['article_slot2']
        ]);
      });
    } else {
      window.googletag.cmd.push(function() {
        window.googletag.slots['article_slot1'] = window.googletag.defineSlot('/21858406487/pag-amarelas/article-page/banner', [[320,50]], 'div-gpt-article-banner')
                 .setTargeting('position', ['banner'])
                 .defineSizeMapping(window.googletag.sizes.mapBanner)
                 .addService(window.googletag.pubads());

        window.googletag.slots['article_slot2'] = window.googletag.defineSlot('/21858406487/pag-amarelas/article-page/side', [[300,250]], 'div-gpt-article-side')
                 .setTargeting('position', ['side'])
                 .defineSizeMapping(window.googletag.sizes.mapSide)
                 .addService(window.googletag.pubads());

        window.googletag.pubads()
           .setTargeting('vertical', verticals)
           .setTargeting('tags-vertical', tags)
           .setTargeting('categoria', categories)
           .setTargeting('location', 'my_location');

        window.googletag.enableServices();

        window.googletag.display("div-gpt-article-banner");
        window.googletag.display("div-gpt-article-side");

        window.googletag.pages.article = true;
      });
    }
  }
}
