import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['options', 'toggler']
  static values = {
    less: { type: String, default: 'Menos' },
    more: { type: String, default: 'Mais' },
    threshold: { type: Number, default: 5 }
  }

  initialize() {
    this.expanded = false
  }

  connect() {
    if (this.optionsTargets.length > this.thresholdValue) this._createToggler()
    if (!this.expanded) this.hideable.forEach((element) => element.classList.add('hide'))
  }

  disconnect() {
    this.expanded = false
    if (this.hasTogglerTarget) this.togglerTarget.remove()
  }

  toggle() {
    this.expanded = !this.expanded
    this.hideable.forEach((element) => element.classList.toggle('hide'))
    this.togglerTarget.innerText = this.expanded ? this.lessValue : this.moreValue
  }

  _createToggler() {
    const button = document.createElement('button')
    button.type = 'button'
    button.classList.add('more-results')
    button.dataset.expandFiltersTarget = `toggler`
    button.dataset.action = `${this.scope.identifier}#toggle`
    button.innerText = this.moreValue
    this.element.appendChild(button)
  }

  get hideable() {
    return this.optionsTargets.slice(this.thresholdValue)
  }
}
