import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["categories", "button"];

  connect() {
    this.showingHidden = false;
  }

  toggleCategories() {
    if (this.showingHidden) {
      this.hideCategories();
    } else {
      this.showCategories();
    }
    this.showingHidden = !this.showingHidden;
  }

  hideCategories() {
    const cellsToHide = this.categoriesTargets.filter((category) => {
      return category.classList.contains("show");
    });
    cellsToHide.forEach((category) => {
      category.style.display = "none";
      category.classList.remove("show");
    });
    this.buttonTarget.querySelector("span").textContent = "Ver todas";
    this.buttonTarget.querySelector(".arrow").classList.remove("rotate");
  }

  showCategories() {
    const hiddenCells = this.categoriesTargets.filter((cell) => {
      const computedStyle = getComputedStyle(cell);
      return computedStyle.display === "none";
    });
    hiddenCells.forEach((category) => {
      category.style.display = "block";
      category.classList.add("show");
    });
    this.buttonTarget.querySelector("span").textContent = "Ver menos";
    this.buttonTarget.querySelector(".arrow").classList.add("rotate");
  }
}
