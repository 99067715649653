import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get shouldLoad() {
    return window.railsEnvironment != 'test';
  }

  track() {
    if (typeof gtag === "function" && event.params['eventName']) {
      gtag('event', event.params['eventName']);
    }
  }
}
