import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'
import * as Utils from 'utils.js';

export default class extends Controller {
  static targets = [ 'location', 'bbne', 'bbsw', 'query', 'tag', 'category', 'group', 'suggestions', 'clear', 'locationInput', 'locationId', 'submit' ]
  static values = { path: String, verticalId: Number }

  connect() {

    // Add event listener to locationInputTarget to hide when location suggestions is visible
    this.locationInputTarget.addEventListener('click', () => {
      const listingsSuggestions = document.querySelector('.listings-suggestions');
      if (listingsSuggestions) {
        listingsSuggestions.classList.add('hide');
      }
    });

    // clear the query field on the home page
    if (document.querySelector(".home.index")) {
      this.queryTarget.value = "";
    }
    
    if (document.querySelector(".categories.show")) {
      this.populateQueryTarget();
      this.toggleClearButton();
    }
    let urlFunction = (value, location, location_id, bbne, bbsw) => {
      let params = { query: value, location: location, location_id: location_id, bbne: bbne, bbsw: bbsw, suggestions: true };

      if (this.verticalIdValue > 0) {
        params = Object.assign(params, { vertical_id: this.verticalIdValue });
      }
      return `${this.pathValue}?${new URLSearchParams(params)}`;
    }

    // Listener for when typing on query input, show/hide the submit and the clear button
    this.queryTarget.addEventListener('input', () => {
      this.toggleClearButton();
      this.toggleSubmitButton();
    })
    
    /// Listener for when a suggestion is selected, show/hide the submit and the clear button
    this.queryTarget.addEventListener('selected-suggestion', () => {
      this.toggleClearButton();
      this.toggleSubmitButton();
  
      if (this.locationInputTarget.value == '') {
        this.locationInputTarget.focus();
      }
    })

    /// Listener for when location change toggle the submit button
    this.locationTarget.addEventListener('change', this.toggleSubmitButton.bind(this));

    /// more listeners
    this.loadAutocomplete(this.queryTarget,
                          this.locationInputTarget,
                          this.locationIdTarget,
                          this.bbneTarget,
                          this.bbswTarget,
                          this.suggestionsTarget,
                          urlFunction);

    /// always hide suggestions when page load
    document.querySelector('.listings-suggestions').classList.add('hide');

    /// same for clear button and submit button
    this.toggleClearButton()
    this.toggleSubmitButton();

  }

  toggleClearButton() {
    // If there is input in the search bar, add a class to hide the clear target
    if (this.queryTarget.value) {
      this.clearTarget.classList.remove('d-none');
      this.clearTarget.classList.add('d-flex');
    } else {
      // If there is no input in the search bar, remove the class to show the clear target
      this.clearTarget.classList.remove('d-flex');
      this.clearTarget.classList.add('d-none');
    }
  }

  trigger() {
    if (this.queryTarget.value.length > 0) { this.queryTarget.select();}
  }

  // We need either a query string OR a Tag OR a Category OR a location to actually search for listings
  submit(event) {
    if (!this._isSubmitDisabled) { // !this.tagTarget.value && !this.categoryTarget.value && !this.groupTarget.value 
      event.preventDefault();
      this.queryTarget.focus();
      this.queryTarget.classList.add('invalid');
      this.queryTarget.addEventListener('transitionend', (evt) => {
        this.queryTarget.classList.remove('invalid');
      })
    }
  }

  populateQueryTarget() {
    // Get the current URL
    const url = window.location.href;

    // Create a new URL object
    const urlObj = new URL(url);

    // Get the pathname and split it into segments
    const pathSegments = urlObj.pathname.split('/');

    // Get the last segment of the path
    let lastSegment = pathSegments[pathSegments.length - 1];

    // Replace '-' with ' '
    lastSegment = lastSegment.replace(/-/g, ' ');

    // Set the value of the queryTarget
    this.queryTarget.value = lastSegment;
  }

  loadAutocomplete(input, location, location_id, bbne, bbsw, results, urlFunction) {
    const debounceMs = 200;

    ['keyup', 'paste'].forEach((event) => {
      this.clearSearchParams();
    });

    ['keyup', 'focus'].forEach((event) => {
      // only clear results when making a new search
      results.innerHTML = "";
      results.scrollTop = 0;

      input.addEventListener(event, Utils.debounce((ev) => {
          if (input.value.length >= 2) {
            if(this.suggestionsTarget.classList.contains('hide') || sessionStorage.getItem("lastSearch") != input.value) {
              // down arrow press
              if(ev.keyCode == 40) {
                const firstRadio = results.querySelector('input[type=radio]');
                if(firstRadio) {
                  firstRadio.checked = true;
                  firstRadio.focus();
                }
                return
              }
              // don't even try to search suggestions if empty string is supplied
              // or if we pressed enter
              if((input.value.trim() == '') || (ev.keyCode == 13)) {
                return
              }

              this.getSuggestions(input, location, location_id, bbne, bbsw, results, urlFunction);
            }
          } else {
            if (this.suggestionsTarget.classList.contains('hide')) {
              this.getPopular(results);
            }
          };
          sessionStorage.setItem("lastSearch", input.value);
        }, debounceMs)
      );
    });


    // Clicking anywhere outside of the results/suggestions box closes it
    document.addEventListener("click", (event) => {
      if (!event.target.closest("." + results.className)
          && event.target.id != input.id
          && !event.target.closest(".search-location")
          && !event.target.closest(".search-box")) {
        results.classList.add('hide');
      };
    });
  }

  handleUrlResponse(responseHtml, container) {
    if (!responseHtml) {
      container.classList.add('hide');
      // empty results should show other partials
      return
    }

    container.innerHTML = responseHtml;
    container.classList.remove('hide')

    const suggestionsNotLinkable = container.querySelectorAll('[data-linkable=false]');
    const suggestionsLinkable = container.querySelectorAll('[data-linkable=true]');

    suggestionsLinkable.forEach((suggestion) => {
      suggestion.addEventListener('click', () => {
        container.classList.add('hide');
      })
    })

    suggestionsNotLinkable.forEach((suggestion) => {
      const suggestionID = suggestion.getAttribute('data-id');
      const suggestionName = suggestion.querySelector('.suggestion-name').textContent;
      const suggestionTarget = suggestion.getAttribute('data-target');
      
      suggestion.addEventListener('click', () => {
        if(suggestionTarget && suggestionID) {
          this.updateFormID(this.element, suggestionTarget, suggestionID)
        }

        this.updateField(this.queryTarget, suggestionName)
        container.classList.add('hide');
      })
    })
  }

  async getSuggestions(input, location, location_id, bbne, bbsw, results, urlFunction) {
    const url = urlFunction(input.value, location.value, location_id.value,  bbne.value, bbsw.value);
    const response = await get(url);

    if (response.ok) {
      response.html.then((responseHtml) => {
        if (url == urlFunction(input.value, location.value, location_id.value, bbne.value, bbsw.value)) {
          this.handleUrlResponse(responseHtml, results)
        };
      })
    }
  }

  async getPopular(results) {
    // Add static content to the results element
    const url = "/suggestions/popular"
    const response = await get(url);
    results.innerHTML = "";

    if (response.ok) {
      response.html.then((responseHtml) => {
        this.handleUrlResponse(responseHtml, results)
      })
    }

    // results.innerHTML = '<div class="popular-searches">Popular searches</div><ul><li>Restaurants</li><li>Hotels</li><li>Attractions</li></ul>';
    // results.classList.remove('hide');
  }

  updateField(field, value) {
    field.value = value;
    field.dispatchEvent(new Event('selected-suggestion'))
  }

  // given a type and value update the
  // supplied form values
  updateFormID(form, type, value) {
    /// Ignore category type for searching
    if (type == 'category')
      return;

    if(type != 'location') {
      // reset values before updating field
      // only if the type isn't location
      // otherwise we are clearing stuff that we shouldn't clear
      this.clearSearchParams();
    }
    const formID = form.querySelector(`#search_${type}_id`);
    formID.value = value;
  }

  clearSearchParams() {
    this.groupTarget.value = "";
    this.tagTarget.value = "";
    this.categoryTarget.value = "";
  }

  clearSearchInput() {
    this.queryTarget.value = "";
    document.querySelector('.listings-suggestions').classList.add('hide');
    this.toggleClearButton();
    this.toggleSubmitButton();
  }
  
  toggleSubmitButton() {
    this.submitTarget.disabled = this._isSubmitDisabled();
  }

  /// Check if the submit button should be disabled
  _isSubmitDisabled() {
    return !this.queryTarget.value || !this.locationTarget.value || !this.locationIdTarget.value;
  }
}
