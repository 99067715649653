import { put } from '@rails/request.js'
import Elements from "./elements"

class DirectDebit {
  constructor(element, form) {
    this.element = element
    this.form = form
    this.stripe = Stripe(window.stripeKey, { locale: 'pt' })
    this.elements = this.stripe.elements()
    this.iban = this.elements.create(
      'iban',
      { style: Elements.styles(), supportedCountries: ['SEPA'], placeholderCountry: 'PT' }
    )
    this.iban.mount(this.element)
    this.iban.addEventListener('change', this.onChange.bind(this))
    this.iban.addEventListener('focus', this.onFocus.bind(this))
    this.isValid = false
    this.isSepaValid = false
    this.bindExistingSepaDebits()
  }

  bindExistingSepaDebits() {
    const existingSepaDebits = this.form.querySelectorAll('.existing-sepa')
    existingSepaDebits.forEach((sepaDebit) => {
      sepaDebit.addEventListener('change', () => {
        this.isValid = true
        this.form.dispatchEvent(new CustomEvent('payment:valid'))
      })
    })
  }

  onChange(iban) {
    if (iban.complete) {
      this.form.dispatchEvent(new CustomEvent('payment:valid'))
      this.isValid = true
      this.isSepaValid = true
    } else if (iban.error) {
      this._triggerError(iban.error.message)
    } else {
      this._triggerError()
    }
  }

  onFocus() {
    const checkedSepaDebit = this.form.querySelector('.existing-sepa:checked')
    if (checkedSepaDebit) checkedSepaDebit.checked = false

    if (!this.isSepaValid) this.form.dispatchEvent(new CustomEvent('payment:invalid'))
  }

  async process() {
    const existingSepaDebit = this.form.querySelector('.existing-sepa:checked')
    if (existingSepaDebit) {
      this._assignSubscription(existingSepaDebit.value)
    } else {
      const response = await put(this.element.dataset.url, { contentType: 'application/json' });
      if (response.ok) {
        response.json.then((result) => this._confirmSeptDebitSetup(result.secret));
      }
    }
  }

  _confirmSeptDebitSetup(secret) {
    const accountHolder = document.querySelector('#account_holder')
    const email = document.querySelector('#email')

    this.stripe.confirmSepaDebitSetup(
      secret,
      {
        payment_method: {
          sepa_debit: this.iban,
          billing_details: {
            name: accountHolder.value,
            email: email.value
          }
        }
      }
    ).then((result) => {
      this._assignSubscription(result.setupIntent.payment_method)
    })
  }

  async _assignSubscription(nonce) {
    // build params for the subscription creation request, we always will receive the nonce
    let params = { nonce: nonce }

    // however for billing day we will just check if we have the element first
    const billingDayElement = this.form.querySelector('[name="sepa_billing_day"]')
    if (billingDayElement) params.billing_day = billingDayElement.value

    const response = await put(this.form.action, { contentType: 'application/json', body: params });
    if (response.ok) {
      response.json.then((result) => {
        if (result.success) {
          this._handleSubscription(result.subscription)
        } else {
          this.form.dispatchEvent(new CustomEvent('payment:error', { bubbles: true, detail: result.message }));
        }
      });
    }
  }

  _handleSubscription(subscription) {
    const { latest_invoice } = subscription
    const { payment_intent } = latest_invoice

    if (payment_intent) {
      const { status } = payment_intent

      if (status === 'requires_action' || status === 'requires_payment_method') {
        this.form.dispatchEvent(new CustomEvent('payment:invalid', { detail: 'Não foi possível completar o pagamento, escolha outro método de pagamento.' }))
      } else {
        this._complete()
      }
    } else {
      this._complete()
    }
  }

  async _complete() {
    const response = await put(`${this.form.action}/complete`, { contentType: 'application/json' });
    if (response.ok) {
      response.json.then((result) => Turbo.visit(result.location));
    }
  }

  _triggerError(message = null) {
    this.form.dispatchEvent(new CustomEvent('payment:invalid', { detail: message }))
    this.isValid = false
    this.isSepaValid = false
  }

  get valid() {
    return this.isValid
  }

  get automatic() {
    return true
  }
}

export default DirectDebit
