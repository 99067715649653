import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["ad", "tab", "panel" ]

  static get shouldLoad() {
    return window.railsEnvironment != 'test';
  }

  connect() {
    this.panelTargets[0].classList.add('is-active');

    // If we have more than one main or secondary featured listing, we will "round-robin" between them and show only one
    this.panelTargets.forEach((panel) => {
      var mainElements = panel.querySelectorAll('.featured-main');
      var secondaryElements = panel.querySelectorAll('.featured-secondary');

      if (mainElements.length > 1) {
        mainElements.forEach((main) => main.classList.add('hide'))
        mainElements[this._randomNumber(0, mainElements.length)].classList.remove('hide');
      }

      if (secondaryElements.length > 1) {
        secondaryElements.forEach((secondary) => secondary.classList.add('hide'))
        secondaryElements[this._randomNumber(0, secondaryElements.length)].classList.remove('hide');
      }
    });

    const target = this._contentForActiveTab();
    const initialHeight = document.body.clientHeight;

    // Commented to not call GoogleAds
    // this._waitForGoogleAds().then(() => {
    //   window.googletag.pubads().addEventListener('slotOnload', (event) => {
    //     const slotIds = ['div-gpt-home-slider', 'div-gpt-vertical-slider'];
    //     // only do stuff when correct ad is loaded
    //     // slotIds has the slot ids we want to match
    //     const slot = event.slot;
    //     if (slotIds.includes(slot.getSlotElementId())) {
    //       this._moveAd(target);

    //       window.addEventListener('resize', () => {
    //         if (initialHeight == document.body.clientHeight) {
    //           this._moveAd(target);
    //         }
    //       })

    //       window.addEventListener("orientationchange", () => {
    //         this._moveAd(target);
    //       });
    //     }
    //   })
    // })

    this.setupAutoRotate();
  }

  setupAutoRotate() {
    this.timer = setInterval(() => {
      const activeTab = this.tabTargets.find(tab => tab.classList.contains('is-active'));

      if (activeTab) {
        let tabs = activeTab.nextSibling ? activeTab.nextSibling : this.tabTargets[0];
        $('.tabs').foundation('selectTab', $("#" + tabs.children[0].dataset.tabsTarget), true);
      }
    }, 10000);
  }

  onClick(event) {
    const id = event.currentTarget.href.split('#').pop()
    const target = document.getElementById(id)
    this.adTarget.classList.remove('active')
    this._moveAd(target)
  }

  _moveAd(target) {
    // save the context to use in the setTimeout
    const that = this;
    setTimeout(() => {
      const ad = that.adTarget.querySelector('.ad');
      if (ad) {
        const child = ad.firstChild;
        const adWidth = parseInt(child.style.width);
        const adHeight = parseInt(child.style.height)
        const offsetHeight = 10
        // extra check if we even have an ad that is visible
        if (adWidth != 1 && adHeight != 1) {
          const zone = target.querySelector('.ad-zone');
          zone.classList.add('active');
          const rect = zone.getBoundingClientRect()
          const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop
          const offset = { top: rect.top + scrollTop, left: rect.left + scrollLeft }
          const left = (zone.clientWidth - adWidth) / 2
          const top = (zone.clientHeight - adHeight) / 2
          that.adTarget.style.top = `${offset.top + top - offsetHeight}px`
          that.adTarget.style.left = `${offset.left + left}px`
          that.adTarget.classList.add('active')
        }
      }
    }, 1000)
  }

  _contentForActiveTab() {
    const id = this.activeTab.querySelector('[data-tabs-target]').href.split('#').pop()
    return document.getElementById(id)
  }

  // don't wait forever for googletag.pubads
  // if we don't load it in 60s we stop trying
  _waitForGoogleAds() {
    return Promise.race([
      new Promise(function (resolve, _reject) {
        (function waitForGoogle() {
          if (window.googletag.pubads) return resolve();
          setTimeout(waitForGoogle, 30);
        })();
      }),
      new Promise(function (_resolve, reject) {
        setTimeout(() => { reject() }, 60000);
      })
    ])
  }

  get activeTab() {
    return this.tabTargets.find((tab) => tab.classList.contains('is-active'))
  }

  _randomNumber(min, max) {
    return Math.floor(Math.random() * max) + min
  }

  disconnect() {
    if (this.timer) clearInterval(this.timer);
  }
}
