import { Controller } from "@hotwired/stimulus"
import ProcessingOverlay from "../../managers/processing_overlay"

export default class extends Controller {
  onSubmit() {
    ProcessingOverlay.show(this.overlayFeedback)
  }

  disconnect() {
    ProcessingOverlay.destroy()
  }

  get overlayFeedback() {
    return this.data.get('feedback') || 'Processing ...'
  }
}
