import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { back: String }

  initialize() {
    // we need to ensure that we listen this event for older browsers that use prefixes
    this.callbacks = ['webkitAnimationEnd', 'oanimationend', 'oAnimationEnd', 'msAnimationEnd', 'animationend'];
  }

  connect() {
    // we will do some matchMedia checks, because we need to do this only
    // in desktop version, and then revert back on mobile/tablet devices
    this.mql = window.matchMedia('(min-width: 1024px)');
    this.layoutChanged = () => { if (!this.mql.matches) this.resetNavigation() }
    this.mql.addEventListener('change', this.layoutChanged);
    // we will by default to a resetNavigation call, because with Turbo we need
    // to make sure that we don't re-add elements, so we clean it before
    this.resetNavigation();

    this.resetCallback = (event) => this.resetListContainer(event);
  }

  navigateCategory(event) {
    var list = event.target.closest('.subcategories-list');
    var location = list.closest('.category-container');
    var categoryList = location.querySelector('.category-list-container');

    var optionsContainer = document.createElement('div');
    optionsContainer.classList.add('category-options');

    // now we create the top row to display the title and allow us to go back
    var backButton = document.createElement('button');
    backButton.classList.add('back-to-list');
    backButton.innerText = this.backValue;
    backButton.addEventListener('click', this.dismissListing.bind(this));
    optionsContainer.appendChild(backButton);

    // prepare the categories list to display
    var categoriesContainer = document.createElement('div');
    categoriesContainer.classList.add('category-list');
    optionsContainer.appendChild(categoriesContainer);
    optionsContainer.classList.add('animated', 'fadeInRight');

    var categories = list.querySelectorAll('.sub-category-p');
    categories.forEach(function (category) {
      var clone = category.cloneNode(true);
      clone.style.display = 'block';
      categoriesContainer.appendChild(clone);
    });
    location.appendChild(optionsContainer);

    categoryList.classList.remove('fadeInLeft');
    categoryList.classList.add('animated', 'fadeOutLeft');

    // if our location is bigger than the category list offsetTop we will wait 1s (animation timing)
    // and then smoothly scroll to the top of the category list
    if (location.offsetTop < window.scrollY) {
      setTimeout(() => window.scrollTo({ top: location.offsetTop, behavior: 'smooth' }), 500);
    }
  }

  dismissListing(event) {
    var container = event.currentTarget.closest('.category-options');
    container.classList.remove('fadeInRight');
    container.classList.add('fadeOutRight');

    this.callbacks.forEach((callback) => {
      container.addEventListener(callback, this.removeOptionsContainer.bind(this));
    });

    var list = container.previousSibling;
    list.classList.remove('fadeOutLeft');
    list.classList.add('fadeInLeft');

    // we will also remove the animation of the main container because we don't want it to
    // randomly animate when we close the menu
    this.callbacks.forEach((callback) => {
      list.addEventListener(callback, this.resetCallback);
    });
  }

  removeOptionsContainer(event) {
    event.target.remove();
  }

  resetListContainer(event) {
    event.target.classList.remove('animated', 'fadeInLeft', 'fadeOutLeft');
    this.callbacks.forEach((callback) => {
      event.target.removeEventListener(callback, this.resetCallback);
    });
  }

  resetNavigation() {
    // basically here we will get any `.category-options` element and remove them, then we do
    // a reset to any `.category-list-container`, we remove the button to see more and show any hidden
    // category
    this.element.querySelectorAll('.category-options').forEach(function (option) {
      option.remove();
    });

    this.element.querySelectorAll('.category-list-container').forEach(function (element) {
      element.classList.remove('animated', 'fadeInLeft', 'fadeOutLeft');
    });
  }

  disconnect() {
    this.mql.removeEventListener('change', this.layoutChanged);
  }
}
