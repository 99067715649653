// app/javascript/controllers/gallery_modal_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "grid", "carousel", "image"]

  connect() {
    this.element.addEventListener('gallery-modal:open', this.open.bind(this))
    this.currentIndex = 0
    this.isCarouselOpen = false
  }

  disconnect() {
    this.element.removeEventListener('gallery-modal:open', this.open.bind(this))
  }

  open(event) {
    this.modalTarget.style.display = "block"
    document.body.style.overflow = "hidden" // Prevent scrolling on the body
    if (event.detail && event.detail.index !== undefined) {
      this.openCarousel(event.detail.index)
    }
  }

  close() {
    this.closeModal()
  }

  closeModal() {
    this.modalTarget.style.display = "none"
    document.body.style.overflow = "" // Restore scrolling on the body
    this.closeCarousel()
  }

  clickOutside(event) {
    if (event.target === this.modalTarget) {
      this.closeModal()
    }
  }

  keyup(event) {
    if (event.key === "Escape") {
      this.closeModal()
    } else if (event.key === "ArrowLeft") {
      this.prevImage()
    } else if (event.key === "ArrowRight") {
      this.nextImage()
    }
  }

  openCarousel(index) {
    this.currentIndex = parseInt(index)
    this.gridTarget.classList.add('hidden-important')
    this.carouselTarget.classList.remove('hidden-important')
    this.isCarouselOpen = true
    this.updateCarousel()
  }

  closeCarousel() {
    this.gridTarget.classList.remove('hidden-important')
    this.carouselTarget.classList.add('hidden-important')
    this.isCarouselOpen = false
  }

  prevImage() {
    if (!this.isCarouselOpen) return
    this.currentIndex = (this.currentIndex - 1 + this.imageTargets.length) % this.imageTargets.length
    this.updateCarousel()
  }

  nextImage() {
    if (!this.isCarouselOpen) return
    this.currentIndex = (this.currentIndex + 1) % this.imageTargets.length
    this.updateCarousel()
  }

  updateCarousel() {
    this.imageTargets.forEach((image, index) => {
      const carouselItem = image.closest('.modal-carousel-item');
      if (index === this.currentIndex) {
        image.classList.remove('hidden-important')
        carouselItem.classList.remove('hidden-important');
      } else {
        image.classList.add('hidden-important')
        carouselItem.classList.add('hidden-important');
      }
    });
  }

  openImage(event) {
    const index = event.currentTarget.dataset.index
    this.openCarousel(index)
  }
}
