import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "element"]

  connect() {
    let checkedOption

    // if we have more than 1 input, we must be dealing with a radio button collection, so we
    // need to retrieve the checked option
    if (this.inputTargets.length > 1) {
      checkedOption = this.inputTargets.find((element) => element.checked).value
    } else {
      checkedOption = this.inputTarget.value
    }

    this._displayElements(checkedOption)
  }

  onSelect(event) {
    this._displayElements(event.target.value)
  }

  _displayElements(option) {
    this.elementTargets.forEach((element) => {
      if (element.dataset.highlight) {
        element.classList.toggle("hidden", option !== element.dataset.highlight)
      }
    })
  }
}
