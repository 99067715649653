import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "links", "template" ]

  add(event) {
    event.preventDefault()
    let content = this.templateTarget.innerHTML.replace(/RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML("beforeend", content)
  }

  remove(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.classForWrapper)
    let input = wrapper.querySelector("input[name*='_destroy']")

    if (input) {
      input.value = 1
      wrapper.style.display = 'none'
    } else {
      wrapper.remove()
    }
  }

  get classForWrapper() {
    return this.data.get('wrapperClass') || 'nested-field'
  }
}
